/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Model(s)
 */
import Model, { ModelError, TModelError, TPayload, TPaginate, Paginate } from "./model";
import User from "./user.model";
import OrderItem from "./orderItem.model";
import Voucher, { IVoucher } from "./voucher.model";
import Brand from "./brand.model";
import { TVoucherUsed } from "./VoucherV2";

export enum ECodeError {
   DATA_DUPLICATE = "DATA_DUPLICATE",
   PRODUCT_VARIANT_UNAVAILABLE = "PRODUCT_VARIANT_UNAVAILABLE",
   PRODUCT_UNAVAILABLE = "PRODUCT_UNAVAILABLE",
   VOUCHER_APPLY_EXCEED_LIMIT = "VOUCHER_APPLY_EXCEED_LIMIT",
   VOUCHER_INACTIVE = "VOUCHER_INACTIVE",
   VOUCHER_EXPIRED = "VOUCHER_EXPIRED",
   VOUCHER_REDEEMED_EXCEED_LIMIT = "VOUCHER_REDEEMED_EXCEED_LIMIT",
}

export enum EOrderStatus {
   initializing = "initializing",
   refunded = "refunded",
   cancelled = "cancelled",
   pending = "pending",
   confirmed = "confirmed",
   handling = "handling",
   delivering = "delivering",
   onpay = "onpay",
   successfullyDelivered = "successfullyDelivered",
}

export enum EPaymentStatus {
   notDelivery = "notDelivery",
   paid = "paid",
}

export enum EPaymentMethods {
   cod = "cod",
   bankTransfer = "bankTransfer",
   momo = "momo",
   // creditCard = "creditCard",
}

export type TOrderItemRaw = {
   ["weight"]: number;
   ["productId"]: string;
   ["brandId"]: string;
   ["variantId"]: string;
   ["quantity"]?: number;
   ["price"]?: number;
   ["priceAfterDiscount"]?: number;
};

export type TDeliveryInfo = {
   ["name"]: string;
   ["phone"]: string;
   // ["address"]: string;
   ["deliveryAddress"]: {
      ["province"]: "Hồ Chí Minh";
      ["district"]: "Quận Bình Thạnh";
      ["ward"]: "Phường 14";
      ["address"]: "10 Phan Bội Châu";
   };
};

export interface IPreCalculate {
   voucherAppliedDetails?: {
      voucher: IVoucher;
   }[];
   // "itemsPriceDetails": [],
   itemsPriceTotal?: number;
   discountByVoucher?: number;
   discountByItem?: number;
   shippingByVoucher?: number;
   pointUsed?: number;
   tax?: number;
   shipping?: number;
   total?: number;
   pointRewarded?: number;
   totalAfterDiscount?: number;
   // totalAfterVoucher?: number;
}

export interface IStatusDated {
   confirmed: string;
   pending: string;
   successfullyDelivered: string;
   cancelled: string;
   refunded: string;
}
export interface IOrder extends Model {
   ["info"]?: {
      ["note"]: string | null;
      ["itemsPriceTotal"]: number | null;
      ["shippingCost"]: number;
      ["shippingByVoucher"]: number;
      ["bonusPoint"]: number | null;
      ["discountByVoucher"]: number | null;
      ["total"]: number | null;
      ["pointRewarded"]: number | null;
      ["paymentMethod"]: EPaymentMethods;
      ["deliveryInfo"]: {
         ["name"]: string;
         ["email"]: string | null;
         ["phone"]: string;
         ["address"]: string;
      };
   };
   ["options"]?: {
      ["status"]?: EOrderStatus;
      ["paymentStatus"]?: EPaymentStatus;
   };

   ["userId"]?: string | null;
   ["xId"]?: string | null;
   ["statusDated"]?: IStatusDated;

   ["statusText"]?: string;
   ["paymentMethodText"]?: string;

   ["__brand"]?: Brand;
   ["__user"]?: User | null;
   ["__orderItems"]?: OrderItem[];
}

export class Order extends Model<IOrder> implements IOrder {
   public ["info"]!: {
      ["note"]: string | null;
      ["itemsPriceTotal"]: number | null;
      ["shippingCost"]: number;
      ["shippingByVoucher"]: number;
      ["bonusPoint"]: number | null;
      ["discountByVoucher"]: number | null;
      ["total"]: number | null;
      ["pointRewarded"]: number | null;
      ["paymentMethod"]: EPaymentMethods;
      ["deliveryInfo"]: {
         ["name"]: string;
         ["email"]: string | null;
         ["phone"]: string;
         ["address"]: string;
      };
   };
   public ["options"]?: {
      ["status"]?: EOrderStatus;
      ["paymentStatus"]?: EPaymentStatus;
   };

   public ["userId"]?: string | null;
   public ["xId"]?: string | null;
   public ["statusDated"]!: IStatusDated;

   public ["statusText"]?: string;
   public ["paymentMethodText"]?: string;

   public ["__brand"]?: Brand;
   public ["__user"]!: User | null;
   public ["__orderItems"]!: OrderItem[];

   constructor(data?: IOrder) {
      super(data);

      Object.assign(this, data);

      if (this.__user && !(this.__user instanceof User)) {
         this.__user = new User(this.__user);
      }

      if (
         this.__orderItems &&
         Array.isArray(this.__orderItems) &&
         this.__orderItems.every((item) => !(item instanceof OrderItem))
      ) {
         this.__orderItems = this.__orderItems.map((item) => new OrderItem(item));
      }

      if (this.options?.status) {
         switch (this.options?.status) {
            case EOrderStatus.successfullyDelivered:
               this.statusText = "Đã giao hàng";
               break;
            case EOrderStatus.cancelled:
               this.statusText = "Đã huỷ";
               break;
            case EOrderStatus.confirmed:
               this.statusText = "Đã xác nhận";
               break;
            case EOrderStatus.delivering:
               this.statusText = "Đang giao hàng";
               break;
            case EOrderStatus.handling:
               this.statusText = "Đang xử lý";
               break;
            case EOrderStatus.initializing:
               this.statusText = "Khởi tạo";
               break;
            case EOrderStatus.pending:
               this.statusText = "Chờ xử lý";
               break;
            case EOrderStatus.refunded:
               this.statusText = "Được hoàn lại";
               break;
            default:
               this.statusText = "Chờ xử lý";
               break;
         }
      }
      switch (this.info?.paymentMethod) {
         // case EPaymentMethods.creditCard:
         //    this.paymentMethodText = "Thanh toán credit card";
         //    break;
         case EPaymentMethods.cod:
            this.paymentMethodText = "Thanh toán bằng tiền mặt (COD)";
            break;
         case EPaymentMethods.bankTransfer:
            this.paymentMethodText = "Thanh toán chuyển khoản";
            break;
         case EPaymentMethods.momo:
            this.paymentMethodText = "Momo";
            break;
         default:
            break;
      }
   }

   /**
    *
    * @param id
    * @returns
    */
   public static async createOrder({
      isBuyNow,
      ...data
   }: {
      data: TOrderItemRaw[];
      isUsePoint: boolean;
      deliveryInfo: TDeliveryInfo;
      paymentMethod: EPaymentMethods;
      note?: string;
      voucherUsed?: TVoucherUsed;
      isBuyNow?: boolean;
   }) {
      try {
         const token = window.localStorage.getItem(tokenKey);

         if (data.data.length < 1) {
            return new ModelError({
               httpCode: 400,
               message: "Order data is empty",
               errors: {
                  order: [
                     {
                        code: "request.createOrder.data.empty",
                        message: "Order data is empty.",
                     },
                  ],
               },
            });
         }

         const response = await fetch(`${apiDomain}/www/order`, {
            method: "POST",
            // ["cache"]: "no-store",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               ...(!token ? undefined : { Authorization: `Bearer ${token}` }),
               ...(isBuyNow ? { isBuyNow: "true" } : undefined),
            },
            body: JSON.stringify(data),
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["newOrders"]: Paginate<Order, IOrder>;
         }>;

         return new Paginate(payload.data.newOrders, Order);
      } catch (error) {
         return new ModelError({
            httpCode: 500,
            message: error.message as string,
            errors: {
               process: [
                  {
                     code: "process.error.5000",
                     message: "Có lỗi xảy ra, vui lòng thử lại sau.",
                  },
               ],
            },
         });
      }
   }

   /**
    *
    * @param id
    * @returns
    */
   public static async preCalculate(data: { data: TOrderItemRaw[]; isUsePoint: boolean; voucherUsed?: TVoucherUsed }) {
      try {
         if (data.data.length < 1) {
            return new ModelError({
               httpCode: 400,
               message: "Order data is empty",
               errors: {
                  order: [
                     {
                        code: "request.createOrder.data.empty",
                        message: "Order data is empty.",
                     },
                  ],
               },
            });
         }

         const response = await this.fetchData({
            method: "POST",
            body: data,
            endPoint: "order/precalculate",
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            preCalculate: IPreCalculate;
         }>;

         return payload.data;
      } catch (error) {
         return error;
      }
   }
}

export default Order;
