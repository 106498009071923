import { Model, IModel, ModelError, TPayload } from "./model";

export interface IABTest extends IModel {}

export enum EABTestType {
   consulting = "consulting",
}

export enum EABConsultingForm {
   A = "A",
   B = "B",
}

export class ABTest extends Model<IABTest> implements IABTest {
   constructor(data: IABTest) {
      super(data);
      Object.assign(this, data);
   }

   public static async abTest(params: { [key: string]: string }) {
      try {
         let endPoint = "ab-test/value-by-key";
         Object.keys(params).forEach((item, index) => {
            if (index === 0) {
               endPoint += `?${item}=${params[item]}`;
               return;
            }
            endPoint += `&${item}=${params[item]}`;
         });

         const response = await Model.fetchData({
            method: "GET",
            endPoint,
         });

         if (response instanceof ModelError) {
            return response;
         }
         const payload = (await response.json()) as TPayload<{
            value: EABConsultingForm;
         }>;

         return payload?.data?.value;
      } catch (error: any) {}
   }
}

export default IABTest;
