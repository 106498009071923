export enum EStep {
   signup = "signup",

   signinStep1 = "signinStep1",
   signinStep2 = "signinStep2",

   signupWithEmail = "signupWithEmail",
   signupSuccess = "signupSuccess",
   signupWithPhone = "signupWithPhone",
   signupWithPhoneAndPassword = "signupWithPhoneAndPassword",

   otpInput = "otpInput",

   otpInputForgot = "otpInputForgot",

   forgotPasswordStep1 = "forgotPasswordStep1",
   forgotPasswordStep2 = "forgotPasswordStep2",
   forgotPasswordStep3 = "forgotPasswordStep3",
   forgotPasswordStep4 = "forgotPasswordStep4",
}

export enum EAuthType {
   signup = "signup",
   signin = "signin",
   forgot = "forgot",
}

export enum ESigninType {
   email = "email",
   phone = "phone",
}
