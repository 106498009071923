import React from "react";
import store from "state";
import { toggleModalAuth } from "state/action/auth";
import Button from "@Components/button";
import { EAuthType } from "./auth/const";
interface IProps {
   handleClose: () => void;
   active: boolean;
   notification?: string | React.ReactElement;
   link?: string;
}

interface IState {}

export default class Notification extends React.Component<IProps & any, IState> {
   private _text = ["Đăng nhập để tiếp tục", "Đăng ký thành công"];
   constructor(props: IProps & any) {
      super(props);
      this.state = {};
   }
   render() {
      return (
         <div className={`notification ${this.props?.active ? "open" : ""}`}>
            <div className="main">
               <div className="panel">
                  <div className="content">{this.props?.notification ?? ""}</div>
                  <Button
                     className="btn"
                     onClick={() => {
                        this.props.handleClose();
                        if (!this.props.notification) {
                           return;
                        }
                        if (this.props.link) {
                           this.props.router.push(this.props.link);
                           return;
                        }
                        if (
                           typeof this.props.notification === "string" &&
                           this._text.includes(this.props.notification)
                        ) {
                           // this.props.router.push("/?login=true");
                           store.dispatch(toggleModalAuth({ visible: true, type: EAuthType.signin }));
                           return;
                        }
                     }}
                  >
                     OK
                  </Button>
               </div>
            </div>
         </div>
      );
   }
}
