import { groupBy } from "lodash";
import Model, { initPaginate, ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import UserCart from "./userCart.model";
import Brand from "./brand.model";
import { apiDomain, tokenKey } from "./__variables";
import { EStockStatus } from "./product.model";
import { EStatus } from "./productVariant.model";
import VoucherV2, {
   IVoucherV2,
   TShopVoucherReq,
   TPlatformVoucherReq,
   TVoucherUsed,
   TShopVoucherRes,
} from "./VoucherV2";

export type TDataCurrentProduct = {
   weight: number;
   productId: string;
   brandId: string;
   variantId: string;
   quantity: number;
   price: number;
   priceAfterDiscount: number;
};

export type TTransformOrdersDataCache = {
   isBuyNow?: boolean;
   orders?: UserCart[];
   isUsePoint?: boolean;
   amount?: number;
   shopVouchers?: TShopVoucherReq[];
   platformVoucher?: TPlatformVoucherReq;
   maxCoinUsedPercent?: number;
   // Buy now
   cartBuyNow?: any;
   dataCurrentProduct?: TDataCurrentProduct[];
};

export type TShopVoucherByBrand = { [brandId: string]: TShopVoucherRes };

export interface IUserCartSummary {
   discountByVoucher: number;
   itemsPriceTotal: number;
   pointRewarded: number;
   pointUsed: number;
   shipping: number;
   shippingByVoucher: number;
   tax: number;
   total: number;
   appliedVouchers?: {
      shopVoucher: {
         discountVoucher?: VoucherV2;
         isNoAuto?: boolean;
      };
      platformVoucher: {
         isNoAutoDiscount?: boolean;
         isNoAutoShipping?: boolean;
         shippingVoucher?: VoucherV2;
         discountVoucher?: VoucherV2;
      };
   };
   errorVouchers: {
      shopVouchers?: {
         brandId: string;
         discountVoucherId: string;
         discountErrorVoucher: { code: string; message: string };
      }[];
      shopVoucher?: {
         brandId: string;
         discountVoucherId: string;
         discountErrorVoucher: { code: string; message: string };
      };
      platformVoucher?: {
         shippingVoucher?: VoucherV2;
         shippingVoucherId: string;
         errorShippingVoucher?: { code: string; message: string };

         discountVoucher?: VoucherV2;
         discountVoucherId: string;
         errorDiscountVoucher?: { code: string; message: string };
      };
   };
}

export interface IUserCartByBrand extends Model {
   ["idBrand"]: string;
   ["items"]: UserCart[];
   ["total"]: number;

   ["__brand"]: Brand;
   ["logistic"]?: {
      fee: {
         a: string;
         cost_id: string;
         delivery: boolean;
         delivery_type: string;
         dt: string;
         extFees: any[];
         fee: number;
         include_vat: string;
         insurance_fee: number;
         name: string;
         promotion_key: string;
         ship_fee_only: number;
      };
      message: string;
      success: boolean;
   };
   ["summary"]?: IUserCartSummary;
   ["discount"]?: {
      ["recommendVoucher"]?: VoucherV2;
      ["voucherMessage"]: string;
      ["appliedVoucher"]: VoucherV2;
      ["isNoAuto"]?: boolean;
   };
}

export default class UserCartByBrand extends Model<IUserCartByBrand> implements IUserCartByBrand {
   public ["idBrand"]: string;
   public ["items"]: UserCart[];
   public ["total"]!: number;

   public ["__brand"]: Brand;
   public ["logistic"]!: {
      fee: {
         a: string;
         cost_id: string;
         delivery: boolean;
         delivery_type: string;
         dt: string;
         extFees: any[];
         fee: number;
         include_vat: string;
         insurance_fee: number;
         name: string;
         promotion_key: string;
         ship_fee_only: number;
      };
      message: string;
      success: boolean;
   };
   public ["summary"]!: IUserCartSummary;
   public ["discount"]?: {
      ["recommendVoucher"]: VoucherV2;
      ["voucherMessage"]: string;
      ["appliedVoucher"]: VoucherV2;
      ["isNoAuto"]?: boolean;
   };

   constructor(data?: IUserCartByBrand) {
      super(data);

      Object.assign(this, data);

      if (this.__brand) {
         this.idBrand = this.__brand._id;
      }
      if (this.items && !(this.items instanceof UserCart)) {
         this.items = this.items.map((imageRawData) => new UserCart(imageRawData));
      }
      // if (this.discount?.voucher) {
      //    this.discount.voucher = new VoucherV2(this.discount.voucher)
      // }
      if (this.discount?.recommendVoucher) {
         this.discount.recommendVoucher = new VoucherV2(this.discount.recommendVoucher);
      }
      if (this.discount?.appliedVoucher) {
         this.discount.appliedVoucher = new VoucherV2(this.discount.appliedVoucher);
      }
      if (this.summary?.appliedVouchers?.shopVoucher?.discountVoucher) {
         this.summary.appliedVouchers.shopVoucher.discountVoucher = new VoucherV2(
            this.summary.appliedVouchers.shopVoucher.discountVoucher
         );
      }
   }

   get totalItem() {
      let total = 0;
      this.items.forEach((cartitem) => {
         total += 1;
      });
      return total;
   }
   get totalAvailable() {
      let total = 0;
      this.items
         .filter(
            (it) =>
               it.__variant.options.stockStatus === EStockStatus.Stocking &&
               it.__variant.options.status === EStatus.enable &&
               it.__product?.options.status === EStatus.enable
         )
         .forEach((cartitem) => {
            total += 1;
         });
      return total;
   }

   public static async paginate({
      page,
      limit,
      sort,
      sortBy,
   }: {
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: "createdAt" | "updatedAt";
   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "Unauthorization",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "process.error.401",
                        ["message"]: "Đăng nhập để tiếp tục.",
                     },
                  ],
               },
            });
         }
         const response = await fetch(`${apiDomain}/www/user/cart/paginate`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               ["page"]: page ? String(page) : "1",
               ["limit"]: limit ? String(limit) : "10",
               ["sort"]: sort ?? "desc",
               ["sort-by"]: sortBy ?? "createdAt",
            },
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: UserCartByBrand[];
            ["shipping"]: {
               recommendVoucher: VoucherV2;
            };
            ["discount"]: {
               recommendVoucher: VoucherV2;
            };
         }>;

         const paginate = new Paginate(
            { docs: payload?.data?.paginator } as TPaginate<UserCartByBrand>,
            UserCartByBrand
         );

         return {
            paginate,
            shipping: payload.data.shipping,
            discount: payload.data.discount,
         };
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
   public static async getCartListCalculate(params?: {
      captchat?: string;
      cartIds?: string[];
      isUsePoint?: boolean;
      // dataOnChange?: {
      //    id: string
      //    variantId: string
      //    offset: string;
      // }[];
      voucherUsed?: TVoucherUsed;
   }) {
      const { captchat, isUsePoint, cartIds, voucherUsed } = params || {};
      const res = await this.fetchData({
         method: "POST",
         endPoint: "user/cart/calculate",
         headers: {
            ...(captchat ? { captchat } : undefined),
            ...(cartIds?.toString() ? { ["cart-ids"]: cartIds.toString() } : undefined),
         },
         body: {
            isUsePoint: Boolean(isUsePoint),
            voucherUsed,
         },
      });
      if (res instanceof ModelError) return res;

      const payload = (await res.json()) as TPayload<{
         ["maxCoinUsedPercent"]: number;
         ["pointCanUse"]: number;
         ["message"]: string;
         ["paginator"]: IUserCartByBrand[];
         ["shipping"]: {
            recommendVoucher?: IVoucherV2;
            appliedVoucher?: IVoucherV2;
            isNoAutoShipping?: boolean;
         };
         ["discount"]: {
            // isCheck: boolean;
            isCheck: boolean;
            recommendVoucher?: IVoucherV2;
            appliedVoucher?: IVoucherV2;
            isNoAutoDiscount?: boolean;
         };
         ["summary"]: IUserCartSummary;
      }>;
      const paginate = payload?.data?.paginator.map((it) => new UserCartByBrand(it));
      const shopVoucher = payload?.data?.summary?.appliedVouchers?.shopVoucher;
      const platformVoucher = payload?.data?.summary?.appliedVouchers?.platformVoucher;
      return {
         maxCoinUsedPercent: payload.data.maxCoinUsedPercent,
         pointCanUse: payload.data.pointCanUse,
         message: payload.data.message,
         paginate,
         summary: {
            ...payload.data.summary,
            // voucher applied and calculate
            appliedVouchers: {
               shopVoucher: {
                  discountVoucher: shopVoucher?.discountVoucher
                     ? new VoucherV2(shopVoucher.discountVoucher)
                     : undefined,
               },
               platformVoucher: {
                  shippingVoucher: platformVoucher?.shippingVoucher
                     ? new VoucherV2(platformVoucher?.shippingVoucher)
                     : undefined,
                  discountVoucher: platformVoucher?.discountVoucher
                     ? new VoucherV2(platformVoucher?.discountVoucher)
                     : undefined,
               },
            },
         },
         // Recomment voucher and selected voucher cannot used and not calculate (for opacity slowly)
         shipping: {
            recommendVoucher: payload.data.shipping?.recommendVoucher
               ? new VoucherV2(payload.data.shipping?.recommendVoucher)
               : undefined,
            appliedVoucher: payload.data.shipping?.appliedVoucher
               ? new VoucherV2(payload.data.shipping?.appliedVoucher)
               : undefined,
            isNoAutoShipping: payload.data.shipping?.isNoAutoShipping,
         },
         discount: {
            recommendVoucher: payload.data.discount?.recommendVoucher
               ? new VoucherV2(payload.data.discount.recommendVoucher)
               : undefined,
            appliedVoucher: payload.data.discount?.appliedVoucher
               ? new VoucherV2(payload.data.discount?.appliedVoucher)
               : undefined,
            isNoAutoDiscount: payload.data.discount?.isNoAutoDiscount,
         },
      };
   }
   public static findItemById(arr: UserCartByBrand[], id: string) {
      if (!id) return;
      for (let i = 0; i < arr.length; i++) {
         const it = arr[i];
         const findItem = it.items.find((it2) => it2._id === id);
         if (findItem) {
            return findItem;
         }
      }
   }
   public static groupBy(arr: UserCart[], type: "brand") {
      if (!arr || arr?.length < 0) return [];
      if (type === "brand") {
         return groupBy(arr, "__brand._id");
      }
      return [];
   }
   public static async createBuyNow({
      addressId,
      data,
      isUsePoint,
      voucherUsed,
   }: {
      addressId?: string;
      data: {
         weight: number;
         productId: string;
         brandId: string;
         variantId: string;
         quantity: number;
         price: number;
         priceAfterDiscount: number;
      }[];
      isUsePoint?: boolean;
      voucherUsed?: TVoucherUsed;
   }) {
      const res = await this.fetchData({
         method: "POST",
         endPoint: "order/review",
         headers: {
            ["address-id"]: addressId || "",
         },
         body: {
            data,
            isUsePoint: Boolean(isUsePoint),
            voucherUsed,
         },
      });
      if (res instanceof ModelError) return res;
      const payload = (await res.json()) as TPayload<{
         responseData: IUserCartByBrand[];
         summary: IUserCartSummary;
         maxCoinUsedPercent: number;
         pointCanUse: number;
         message: string;
      }>;

      const summary = payload?.data?.summary;
      const shopVoucher = summary?.appliedVouchers?.shopVoucher;
      const platformVoucher = summary?.appliedVouchers?.platformVoucher;

      return {
         maxCoinUsedPercent: payload.data.maxCoinUsedPercent,
         pointCanUse: payload.data.pointCanUse,
         message: payload.data.message,
         cartBuyNow: new Paginate({ ...initPaginate, docs: payload.data.responseData }, UserCartByBrand),
         summary: {
            ...summary,
            appliedVouchers: {
               ...summary.appliedVouchers,
               shopVoucher: {
                  isNoAuto: shopVoucher?.isNoAuto,
                  discountVoucher: shopVoucher?.discountVoucher
                     ? new VoucherV2(shopVoucher.discountVoucher)
                     : undefined,
               },
               platformVoucher: {
                  isNoAutoDiscount: platformVoucher?.isNoAutoDiscount,
                  isNoAutoShipping: platformVoucher?.isNoAutoShipping,
                  shippingVoucher: platformVoucher?.shippingVoucher
                     ? new VoucherV2(platformVoucher?.shippingVoucher)
                     : undefined,
                  discountVoucher: platformVoucher?.discountVoucher
                     ? new VoucherV2(platformVoucher?.discountVoucher)
                     : undefined,
               },
            },
         },
      };
   }
   public static async userCarts({
      cartIds,
      addressId,
      usePoint,
      voucherUsed,
   }: {
      cartIds: string[];
      addressId?: string;
      usePoint?: boolean;
      voucherUsed: TVoucherUsed;
   }) {
      try {
         const res = await Model.fetchData({
            endPoint: `order/review`,
            ["method"]: "POST",
            ["headers"]: {
               ["sort-by"]: "createdAt",
               ["cart-ids"]: cartIds?.join(","),
               // ["use-point"]: usePoint?.toString(),
               ...(addressId ? { ["address-id"]: addressId } : undefined),
            },
            body: {
               isUsePoint: Boolean(usePoint),
               voucherUsed,
            },
         });

         if (res instanceof ModelError) {
            return res;
         }

         const payload = (await res.json()) as TPayload<{
            ["maxCoinUsedPercent"]: number;
            ["responseData"]: UserCartByBrand[];
            ["summary"]: IUserCartSummary;
            pointCanUse: number;
            message: string;
         }>;

         const paginate = new Paginate(
            { docs: payload.data.responseData } as TPaginate<UserCartByBrand>,
            UserCartByBrand
         );

         const shopVoucher = payload?.data?.summary?.appliedVouchers?.shopVoucher;
         const platformVoucher = payload?.data?.summary?.appliedVouchers?.platformVoucher;

         return {
            maxCoinUsedPercent: payload.data.maxCoinUsedPercent,
            pointCanUse: payload.data.pointCanUse,
            message: payload.data.message,
            paginate,
            summary: {
               ...payload.data.summary,
               appliedVouchers: {
                  ...payload.data.summary.appliedVouchers,
                  shopVoucher: {
                     isNoAuto: shopVoucher?.isNoAuto,
                     discountVoucher: shopVoucher?.discountVoucher
                        ? new VoucherV2(shopVoucher.discountVoucher)
                        : undefined,
                  },
                  platformVoucher: {
                     isNoAutoShipping: platformVoucher?.isNoAutoShipping,
                     isNoAutoDiscount: platformVoucher?.isNoAutoDiscount,
                     shippingVoucher: platformVoucher?.shippingVoucher
                        ? new VoucherV2(platformVoucher?.shippingVoucher)
                        : undefined,
                     discountVoucher: platformVoucher?.discountVoucher
                        ? new VoucherV2(platformVoucher?.discountVoucher)
                        : undefined,
                  },
               },
            },
         };
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
}
