/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { cloudFront } from "@Config/__env";
import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import Model, { Paginate, ModelError, TPayload, TPaginate, TModelError } from "./model";
import Media, { IMediaInfo, IMediaOptions } from "./modelMedia.model";
import { fetchWithTimeout, handleSSRResponseError } from "@Utils/index";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete",
}
export interface IBrandLogo extends Media {
   ["status"]?: EStatus | null;
}

export interface IBrand extends Model {
   ["companyId"]?: string | null;
   ["info"]?: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
      ["origin"]?: string | null;
      ["isMigrated"]?: boolean;
   };
   ["__productCount"]?: number;
   ["options"]?: IMediaOptions;
   ["logo"]?: IMediaInfo;
   ["$url"]?: any | null;
   ["$url64ResizeMax"]?: string;
   ["$url5And6ColResizeMax"]?: string;
   ["$url3And4ColResizeMax"]?: string;
   ["$url2ColResizeMax"]?: string;
   ["$url1ColResizeMax"]?: string;
}

export class Brand extends Model<IBrand> implements IBrand {
   public ["companyId"]!: string | null;
   public ["info"]!: {
      ["name"]?: string | null;
      ["slug"]?: string | null;
      ["description"]?: string | null;
      ["slug"]?: string | null;
      ["isMigrated"]?: boolean;
   };
   public ["__productCount"]?: number;
   public ["options"]!: IMediaOptions;
   public ["logo"]?: IMediaInfo;
   public ["$url"]?: any | null;
   public ["$url64ResizeMax"]?: string;
   public ["$url5And6ColResizeMax"]?: string;
   public ["$url3And4ColResizeMax"]?: string;
   public ["$url2ColResizeMax"]?: string;
   public ["$url1ColResizeMax"]?: string;

   constructor(data?: IBrand) {
      super(data);

      Object.assign(this, data);
      const filePath =
         this.options?.isMigrated || this.info?.isMigrated
            ? `${this.logo?.originalName}`
            : `${this._id}${this.logo?.extension}`;
      if (filePath && this.logo?.protocol && this.logo?.domain && this.logo?.container) {
         this.$url = `${this.logo?.protocol}://${this.logo?.domain}/${this.logo?.container}/${filePath}`;
         this.$url64ResizeMax = `${cloudFront}/image-resized/64x64_max/${this.logo?.container}/${filePath}`;
         this.$url5And6ColResizeMax = `${cloudFront}/image-resized/256x2560_max/${this.logo?.container}/${filePath}`;
         this.$url3And4ColResizeMax = `${cloudFront}/image-resized/384x3840_max/${this.logo?.container}/${filePath}`;
         this.$url2ColResizeMax = `${cloudFront}/image-resized/768x7680_max/${this.logo?.container}/${filePath}`;
         this.$url1ColResizeMax = `${cloudFront}/image-resized/1024x10240_max/${this.logo?.container}/${filePath}`;
      }
   }

   /**
    * code improve ngày 23 tháng 01 năm 2025
    */
   public static async paginate({
      page,
      limit,
      sort,
      sortBy,
      ids,
   }: {
      keywords?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: "info.publishedAt";
      creatorId?: string;
      ids?: string;
      offset?: number;
      cookiesToken?: string;
   }) {
      try {
         const response = await this.fetch({
            ["method"]: "GET",
            ["endPoint"]: "brand/paginate",
            ["headers"]: {
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(500) : String(limit),
               ["sort"]: !sort ? "desc" : encodeURI(sort),
               ["sort-by"]: !sortBy ? "createdAt" : encodeURI(sortBy),
               ...(!!ids && { ["ids"]: ids }),
            },
         });

         if (response instanceof ModelError) {
            return response;
         }

         // Ép kiểu dữ liệu chính xác để tránh lỗi TypeScript
         const data = response.data as { paginator: TPaginate<IBrand> };

         return new Paginate(data.paginator, Brand);
      } catch (error) {}
   }

   public static async paginateBrandDay() {
      try {
         const response = await this.fetch({
            ["method"]: "GET",
            ["endPoint"]: "brand/brand-day",
         });
         if (response instanceof ModelError) {
            return response;
         }
         const payload = response as TPayload<{
            ["paginator"]: TPaginate<IBrand>;
         }>;

         return payload?.data?.paginator?.docs;
      } catch (error) {
         return [];
      }
   }

   public static async topBrands() {
      try {
         const response = await this.fetch({
            ["method"]: "GET",
            ["endPoint"]: "brand/on-shop",
         });
         if (response instanceof ModelError) {
            return response;
         }
         // The response is already a JSON payload, no need to parse text
         const payload = response as TPayload<{
            ["paginator"]: TPaginate<{
               _id: string;
               info: {
                  name: string;
                  slug: string;
               };
               thumbnailUrl: string;
               mediaUrl: string;
            }>;
         }>;

         return payload?.data?.paginator?.docs;
      } catch (error) {}
   }

   /**
    * code improve ngày 23 tháng 01 năm 2025
    */
   public static async getBrandById(slug: string) {
      try {
         const res = await fetchWithTimeout(`${apiDomain}/www/brand`, {
            method: "GET",
            headers: {
               Origin: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               slug: encodeURI(slug),
            },
         });

         // Kiểm tra lỗi HTTP thông qua handleSSRResponseError
         await handleSSRResponseError(res);

         // Đọc response dưới dạng text trước khi parse JSON
         const payloadText = await res.text();

         if (!payloadText) {
            throw new ModelError({ message: "Response payload is empty.", httpCode: 500 });
         }

         let jsonPayload: TPayload<{ brand: Brand }>;

         try {
            jsonPayload = JSON.parse(payloadText);
         } catch {
            throw new ModelError({ message: "Invalid JSON response format.", httpCode: 500 });
         }

         // Kiểm tra dữ liệu trả về có hợp lệ không
         if (!jsonPayload?.data?.brand) {
            throw new ModelError({ message: "Dữ liệu phản hồi không hợp lệ hoặc thiếu brand.", httpCode: 500 });
         }

         return new Brand(jsonPayload.data.brand);
      } catch (error) {}
   }
}

export default Brand;
