import { Dialog } from "@mui/material";

export const ShowModalAtURL = ["/feed", "/post", "/hoi-dap"];

export function isShowModalWelcome(pathname: string): boolean {
   let status = false;
   ShowModalAtURL.forEach((url) => {
      if (pathname.includes(url) || pathname.includes(url) || pathname.includes(url)) {
         status = true;
      }
   });
   return status;
}

export default function ModalRegisterRewardCoin({
   visible,
   onClose,
   onSubmit,
}: {
   visible?: boolean;
   onClose?: () => void;
   onSubmit?: () => void;
}) {
   const handleClose = () => {
      onClose?.();
   };
   return (
      <>
         <Dialog open={Boolean(visible)} className="rewardcoin" onClose={handleClose}>
            <div className="rewardcoin__content">
               <div className="rewardcoin__head">
                  <div className="rewardcoin__head__title">Đăng ký thành công!</div>
                  <div className="subtitle">Chúc mừng bạn đã nhận được</div>
                  <img className="rewardcoin__head__icon" src="/assets/img/reward-coin-icon.png" />
                  <div className="coin-number">100.000 Xu</div>
               </div>
               <div className="rewardcoin__action">
                  <button className="other" onClick={onClose}>
                     OK
                  </button>
                  <button className="create-post" onClick={onSubmit}>
                     Nhận thêm Xu
                  </button>
               </div>
            </div>
         </Dialog>
      </>
   );
}
