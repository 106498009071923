/* eslint-disable max-len */
import MenuItem from "@Models/menuItem.model";
import MenuDropdown from "./MenuDropdown";

interface IProps {
   menus: MenuItem[];
}

export default function ShopMenu({ menus }: IProps) {
   return (
      <MenuDropdown menus={menus}>
         <svg
            className="menu-icon-collapse"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
         >
            <path
               d="M17 1.33398C17 0.7817 16.5224 0.333984 15.9333 0.333984L2.06667 0.333984C1.47756 0.333984 1 0.781699 1 1.33398C1 1.88627 1.47756 2.33398 2.06667 2.33398L15.9333 2.33398C16.5224 2.33398 17 1.88627 17 1.33398Z"
               fill="black"
            />
            <path
               d="M17 6.00065C17 5.44837 16.5224 5.00065 15.9333 5.00065L2.06667 5.00065C1.47756 5.00065 1 5.44837 1 6.00065C1 6.55294 1.47756 7.00065 2.06667 7.00065L15.9333 7.00065C16.5224 7.00065 17 6.55294 17 6.00065Z"
               fill="black"
            />
            <path
               d="M11.6667 10.6673C11.6667 10.115 11.1891 9.66732 10.6 9.66732L2.06667 9.66732C1.47756 9.66732 1 10.115 1 10.6673C1 11.2196 1.47756 11.6673 2.06667 11.6673L10.6 11.6673C11.1891 11.6673 11.6667 11.2196 11.6667 10.6673Z"
               fill="black"
            />
            <path
               d="M17 1.33398C17 0.7817 16.5224 0.333984 15.9333 0.333984L2.06667 0.333984C1.47756 0.333984 1 0.781699 1 1.33398C1 1.88627 1.47756 2.33398 2.06667 2.33398L15.9333 2.33398C16.5224 2.33398 17 1.88627 17 1.33398Z"
               stroke="black"
               strokeWidth="0.5"
            />
            <path
               d="M17 6.00065C17 5.44837 16.5224 5.00065 15.9333 5.00065L2.06667 5.00065C1.47756 5.00065 1 5.44837 1 6.00065C1 6.55294 1.47756 7.00065 2.06667 7.00065L15.9333 7.00065C16.5224 7.00065 17 6.55294 17 6.00065Z"
               stroke="black"
               strokeWidth="0.5"
            />
            <path
               d="M11.6667 10.6673C11.6667 10.115 11.1891 9.66732 10.6 9.66732L2.06667 9.66732C1.47756 9.66732 1 10.115 1 10.6673C1 11.2196 1.47756 11.6673 2.06667 11.6673L10.6 11.6673C11.1891 11.6673 11.6667 11.2196 11.6667 10.6673Z"
               stroke="black"
               strokeWidth="0.5"
            />
         </svg>
         <span>Danh mục</span>
         <svg
            className="arrow-down"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
         >
            <path d="M1 1L5 5L9 1" stroke="black" strokeLinecap="round" />
         </svg>
      </MenuDropdown>
   );
}
