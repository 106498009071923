import { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import MenuItem from "models/menuItem.model";
import { useAppStoreContext } from "@Components/AppStoreContext";
import ShopMenuList from "@Components/headerv2/ShopMenuList";

interface IProps extends PropsWithChildren {
   menus: MenuItem[];
   className?: string;
   dropdownClassName?: string;
}

export default function MenuDropdown({ className, dropdownClassName, menus, children, ...props }: IProps) {
   const context = useAppStoreContext();

   const [showMenu, setShowMenu] = useState<boolean>();
   const [level2, setLevel2] = useState<MenuItem[] | undefined>();
   const [level3, setLevel3] = useState<MenuItem[] | undefined>();
   const [level4, setLevel4] = useState<MenuItem[] | undefined>();

   const clearAllLevelMenu = () => {
      setLevel2(undefined);
      setLevel3(undefined);
      setLevel4(undefined);
   };

   return (
      <div
         className={classNames("shop-menu", className)}
         onMouseLeave={() => {
            setShowMenu(false);
            clearAllLevelMenu();
         }}
         {...props}
      >
         <div className="root-menu" onMouseEnter={() => setShowMenu(true)}>
            {children}
         </div>
         <div
            className={classNames("dropdown-menu-shop", dropdownClassName)}
            style={{
               ...{
                  display: showMenu ? "block" : "none",
                  pointerEvents: showMenu ? "auto" : "none",
               },
            }}
         >
            <div className="menu-content">
               {/* Lv1 */}
               <ShopMenuList
                  menus={menus}
                  parentIds={level2?.[0]?.parentId ? [level2?.[0]?.parentId] : []}
                  isAvailableLevel={level2 && level2?.length > 0}
                  setMenuItems={(menuLevel2) => {
                     setLevel2(menuLevel2);
                     setLevel3(undefined);
                     setLevel4(undefined);
                  }}
                  onClickItem={() => context.toggleProgressbar()}
               />
               {level2 && (
                  <ShopMenuList
                     menus={level2}
                     parentIds={level3?.[0]?.parentId ? [level3?.[0]?.parentId] : []}
                     isAvailableLevel={level3 && level3?.length > 0}
                     setMenuItems={(menuLevel3) => {
                        setLevel3(menuLevel3);
                        setLevel4(undefined);
                     }}
                     onClickItem={() => context.toggleProgressbar()}
                  />
               )}
               {level3 && (
                  <ShopMenuList
                     menus={level3}
                     parentIds={level4?.[0]?.parentId ? [level4?.[0]?.parentId] : []}
                     isAvailableLevel={level4 && level4?.length > 0}
                     setMenuItems={(menuLevel4) => {
                        setLevel4(menuLevel4);
                     }}
                     onClickItem={() => context.toggleProgressbar()}
                  />
               )}
               {level4 && (
                  <ShopMenuList
                     menus={level4}
                     isAvailableLevel={false}
                     onClickItem={() => context.toggleProgressbar()}
                  />
               )}
            </div>
         </div>
      </div>
   );
}
