import { Popover } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useRouter } from "next/router";
import Loadingv2 from "@Components/Loading/v2";
import UserCartByBrand, { TShopVoucherByBrand } from "@Models/userCardByBrand";
import useCustomHookState from "hook/useCustomHookState";
import store, { RootState } from "state";
import UserCart from "@Models/userCart.model";
import Brand from "@Models/brand.model";
import { ModelError } from "@Models/model";
import { currency, replaceAllDuplicateItem } from "utils";
import { EStatus, EStockStatus } from "@Models/productVariant.model";
import VoucherV2, { TVoucherUsed } from "@Models/VoucherV2";
import { fetchCartListAndCalculate } from "state/cart/actions";
import { ICartReducer } from "state/cart/reducer";
import Button from "@Components/button";
import { IPreCalculate } from "@Models/order.model";
import { useBookmark } from "hook/useBookmark";
import UserBookmark from "@Models/userBookmark.model";
import Product from "@Models/product.model";
import styles from "./PopoverShopCart.module.scss";
import CartByBrandItem from "./CartByBrandItem";

export default function PopoverShopCart({ anchorEl, onClose }: { anchorEl?: Element | null; onClose?: () => void }) {
   // const { auth } = useSelector<RootState>((state) => state) as any;
   const cartStore = useSelector((state: RootState) => state.cart) as ICartReducer;

   const router = useRouter();

   const { state, setState } = useCustomHookState<{
      params: {
         captchat?: string;
         cartIds?: string[];
         isUsePoint?: boolean;
         voucherUsed?: TVoucherUsed;
      };
      carts: UserCartByBrand[];
      initSelectAll?: boolean;
      totalInitPrice?: number;
      orders: UserCart[];
      cartIds: string[];
      brandOrders: Brand[];
      outstockProductVariantId?: string[];
      isUsePoint: boolean;

      // Shop voucher
      cartByBrandInteract?: UserCartByBrand;
      shopVouchers: TShopVoucherByBrand;
      loading?: boolean;

      // Platform voucher
      platformShippingVoucher?: VoucherV2;
      platformDiscountVoucher?: VoucherV2;
      dialogPlatformDiscount?: boolean;
      isPlatformNoAutoApply?: boolean;
      maxCoinUsedPercent?: number;

      preCalculate?: IPreCalculate;
      error?: ModelError | null;
   }>({
      loading: true,
      params: {},
      carts: [],
      initSelectAll: true,
      totalInitPrice: 0,
      isUsePoint: false,
      orders: [],
      cartIds: [],
      brandOrders: [],
      shopVouchers: {},
   });

   useEffect(() => {
      store.dispatch(
         fetchCartListAndCalculate({
            voucherUsed: {
               platformVoucher: {
                  isNoAutoShipping: true,
                  isNoAutoDiscount: true,
               },
            },
         })
      );
   }, []);

   useEffect(() => {
      if (cartStore.loading) {
         return;
      }
      if (cartStore.error) {
         setState((prev) => ({
            ...prev,
            loading: false,
            error: cartStore.error,
         }));
         return;
      }
      const result = handleCartListAndCalculate(cartStore.data);
      setState((prev) => {
         let newState = {
            ...prev,
            loading: false,
            carts: result.carts ? result.carts : [],
            preCalculate: result.summary,
            shopVouchers: result.shopVouchers,
            platformShippingVoucher: result.shippingVoucher,
            platformDiscountVoucher: result.discountVoucher,
            isPlatformNoAutoApply: result.isPlatformNoAutoApply,
            maxCoinUsedPercent: result.maxCoinUsedPercent,
            outstockProductVariantId: result.outStockCartIds,
         };
         if (prev.initSelectAll) {
            const { orders, brandOrders, cartIds, totalPrice } = getAllBrandsAndProductsAvailable(result.carts || []);
            newState = {
               ...newState,
               orders,
               brandOrders,
               cartIds,
               totalInitPrice: totalPrice,
            };
         }
         return newState;
      });
   }, [cartStore]);

   function handleCartListAndCalculate(_resCard: ICartReducer["data"] | undefined) {
      const { paginate: carts, shipping, discount, maxCoinUsedPercent, summary } = _resCard || {};

      // get outstoct
      const outStockCartIds: string[] = [];
      carts?.forEach((cartItem) => {
         if (cartItem.items) {
            cartItem.items.forEach((item) => {
               const isEnable =
                  item.__variant.options.stockStatus === EStockStatus.Stocking &&
                  item.__variant.options.status === EStatus.enable &&
                  item.__product?.options.status === EStatus.enable;
               if (!isEnable) {
                  outStockCartIds.push(item._id);
               }
            });
         }
      });
      // get shop voucher
      let getShopVouchers: TShopVoucherByBrand = {};
      carts?.forEach((it) => {
         if (it.__brand._id) {
            getShopVouchers[it.__brand._id] = {
               voucher: it.discount?.appliedVoucher,
               recommendVoucher: it.discount?.recommendVoucher,
               isNoAuto: it.discount?.isNoAuto,
            };
         }
      });

      return {
         carts,
         summary,
         outStockCartIds,
         shopVouchers: getShopVouchers,
         shippingVoucher: shipping?.appliedVoucher, // || shipping?.recommendVoucher,
         discountVoucher: discount?.appliedVoucher, // || discount?.recommendVoucher,
         isPlatformNoAutoApply: Boolean(discount?.isNoAutoDiscount),
         maxCoinUsedPercent: maxCoinUsedPercent,
      };
   }
   function renderEmptyCart() {
      return (
         <div className={styles["empty-cart"]}>
            <svg
               className={styles["empty-icon"]}
               width="154"
               height="122"
               viewBox="0 0 154 122"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
            >
               <g filter="url(#filter0_f_11625_1117)">
                  <rect x="50.2227" y="92.3689" width="52.9996" height="5.12899" fill="#1B3554" />
               </g>
               <rect x="50.6501" y="22.2723" width="52.9996" height="33.7659" fill="#E0E5EC" />
               <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M50.6501 22.2722V73.5622H42.1018V42.5474L46.3766 35.9864L50.6501 22.2722Z"
                  fill="#CFD4DC"
               />
               <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M103.222 22.2722V73.5622H111.771V42.5474L107.496 35.9864L103.222 22.2722Z"
                  fill="#CFD4DC"
               />
               <path
                  d="M42.1018 42.3608H111.771V97.6265C111.771 100.388 109.532 102.627 106.771 102.627H47.1018C44.3404 102.627 42.1018 100.388 42.1018 97.6265V42.3608Z"
                  fill="white"
                  stroke="url(#paint0_linear_11625_1117)"
                  stroke-opacity="0.2"
                  strokeWidth="0.5"
               />
               <path
                  opacity="0.5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M63.0457 65.9286C63.0457 66.2277 63.1993 66.5059 63.465 66.6431C64.0564 66.9486 65.1864 67.445 66.2845 67.445C67.3822 67.445 68.4799 66.949 69.0519 66.6435C69.3097 66.5058 69.4569 66.2332 69.4569 65.941C69.4569 65.2528 68.6643 64.8016 68.0168 65.0347C67.4966 65.222 66.885 65.3736 66.2684 65.3736C65.6395 65.3736 65.0107 65.2159 64.4776 65.0236C63.8313 64.7903 63.0457 65.2415 63.0457 65.9286Z"
                  fill="#1B3554"
               />
               <path
                  opacity="0.5"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M84.8438 65.9286C84.8438 66.2277 84.9974 66.5059 85.2631 66.6431C85.8545 66.9486 86.9845 67.445 88.0826 67.445C89.1803 67.445 90.278 66.949 90.85 66.6435C91.1078 66.5058 91.255 66.2332 91.255 65.941C91.255 65.2528 90.4624 64.8016 89.8149 65.0347C89.2947 65.222 88.6831 65.3736 88.0665 65.3736C87.4376 65.3736 86.8088 65.2159 86.2757 65.0236C85.6294 64.7903 84.8438 65.2415 84.8438 65.9286Z"
                  fill="#1B3554"
               />
               <ellipse opacity="0.5" cx="77.1504" cy="74.8453" rx="6.83866" ry="2.99321" fill="#1B3554" />
               <circle opacity="0.149343" cx="61.336" cy="48.7717" r="2.5645" fill="#1B3554" />
               <circle opacity="0.149343" cx="92.5372" cy="48.7717" r="2.5645" fill="#1B3554" />
               <path
                  d="M61.3357 48.6403V32.7435C61.3357 24.1275 68.3204 17.1429 76.9364 17.1429C85.5524 17.1429 92.5371 24.1275 92.5371 32.7435V48.6403"
                  stroke="#5E7085"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
               />
               <defs>
                  <filter
                     id="filter0_f_11625_1117"
                     x="26.0357"
                     y="68.1819"
                     width="101.373"
                     height="53.5029"
                     filterUnits="userSpaceOnUse"
                     color-interpolation-filters="sRGB"
                  >
                     <feFlood flood-opacity="0" result="BackgroundImageFix" />
                     <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                     <feGaussianBlur stdDeviation="12.0935" result="effect1_foregroundBlur_11625_1117" />
                  </filter>
                  <linearGradient
                     id="paint0_linear_11625_1117"
                     x1="76.9362"
                     y1="42.3608"
                     x2="76.9362"
                     y2="102.627"
                     gradientUnits="userSpaceOnUse"
                  >
                     <stop />
                     <stop offset="1" stop-opacity="0" />
                  </linearGradient>
               </defs>
            </svg>
            <div className={styles["title"]}>Chưa có sản phẩm</div>
            <div className={styles["des"]}>Tiếp tục mua sắm và quay lại khi có sản phẩm bạn nhé!</div>
         </div>
      );
   }
   function getShopVoucherIdDatas(shopVouchers?: TShopVoucherByBrand) {
      if (!shopVouchers) return undefined;
      const arrs: any[] = Object.entries(shopVouchers)
         .map(([brandId, discount]) => {
            if (!discount.isNoAuto) return;
            return {
               brandId,
               discountVoucherId: discount?.voucher?._id,
               isNoAuto: discount?.isNoAuto,
            };
         })
         .filter((o) => (o && o.brandId && o.discountVoucherId) || (o && o.isNoAuto !== undefined));
      return arrs.length > 0 ? arrs : undefined;
   }
   function getTotalProductAvailable() {
      let total = 0;
      if (state.carts) {
         state.carts?.forEach((item) => {
            total += item.totalAvailable;
         });
      }
      return total;
   }
   function getPlatformVoucherIdDatas(platformVoucher: {
      isPlatformNoAutoApply?: boolean;
      shippingVoucher?: VoucherV2;
      discountVoucher?: VoucherV2;
   }) {
      return {
         shippingVoucherId: platformVoucher.isPlatformNoAutoApply ? platformVoucher?.shippingVoucher?._id : undefined,
         discountVoucherId: platformVoucher.isPlatformNoAutoApply ? platformVoucher?.discountVoucher?._id : undefined,
         isNoAutoShipping: platformVoucher?.isPlatformNoAutoApply,
         isNoAutoDiscount: platformVoucher?.isPlatformNoAutoApply,
      };
   }
   function updateCartUsersAndVoucher({
      cartIds,
      isUsePoint,
      shopVouchers,
      isPlatformNoAutoApply,
      platformDiscountVoucher,
      platformShippingVoucher,
   }: {
      cartIds?: string[];
      isUsePoint?: boolean;
      shopVouchers?: TShopVoucherByBrand;
      isPlatformNoAutoApply?: boolean;
      platformDiscountVoucher?: any;
      platformShippingVoucher?: any;
   }) {
      setState({ loading: true });
      const _isPlatformNoAutoApply =
         isPlatformNoAutoApply !== undefined ? isPlatformNoAutoApply : state.isPlatformNoAutoApply;
      store.dispatch(
         fetchCartListAndCalculate({
            isUsePoint: isUsePoint !== undefined ? isUsePoint : state.isUsePoint,
            cartIds: cartIds !== undefined ? cartIds : state.cartIds,
            voucherUsed: {
               platformVoucher: {
                  isNoAutoShipping: true,
                  isNoAutoDiscount: true,
               },
            },
            // voucherUsed: {
            //    shopVouchers: shopVouchers !== undefined ? getShopVoucherIdDatas(shopVouchers) : getShopVoucherIdDatas(state.shopVouchers),
            //    platformVoucher: getPlatformVoucherIdDatas({
            //       isPlatformNoAutoApply: _isPlatformNoAutoApply,
            //       discountVoucher: platformDiscountVoucher || state.platformDiscountVoucher,
            //       shippingVoucher: platformShippingVoucher || state.platformShippingVoucher,
            //    }),
            // },
         })
      );
   }
   async function reHandleUpdateUserCart({
      brandId,
      productCartId,
      resUpdate,
   }: {
      brandId: string;
      productCartId: string;
      resUpdate: UserCart;
   }) {
      const updateUserOrdersCartItem = (_arr: UserCart[], _resUpdate: UserCart) => {
         return _arr.map((item) =>
            item._id === productCartId
               ? new UserCart({
                    ...item,
                    info: {
                       ...item.info,
                       quantity: _resUpdate.info.quantity,
                    },
                    __variant: _resUpdate.__variant,
                 } as UserCart)
               : item
         );
      };
      const updateUserCartItemByBrand = (
         _arr: UserCartByBrand[],
         _brandId: string,
         _resUpdate: UserCart
      ): UserCartByBrand[] => {
         return _arr.map((item) => {
            if (item.__brand._id === _brandId) {
               item.items = updateUserOrdersCartItem(item.items, _resUpdate);
               return item;
            } else {
               return item;
            }
         });
      };

      const orders = updateUserOrdersCartItem(state.orders, resUpdate);
      const cartItems = updateUserCartItemByBrand(state.carts ?? [], brandId, resUpdate);

      setState({
         orders,
         carts: cartItems,
         initSelectAll: false,
      });

      updateCartUsersAndVoucher({ cartIds: state.cartIds });
   }
   async function updateItemCart(cartItem: UserCartByBrand, productCart: UserCart, offset: number) {
      try {
         const update = await UserCart.update({
            offset,
            cartId: productCart._id,
            variantId: productCart.__variant._id,
         });
         if (update instanceof ModelError) {
            throw Error(update.message);
         }
         reHandleUpdateUserCart({
            brandId: cartItem.__brand._id,
            productCartId: productCart._id,
            resUpdate: update,
         });
      } catch (error) {}
   }
   function handleCheckOrderByBrand(byBrand: UserCartByBrand) {
      const { brandOrders, orders } = state;
      const isAvailableBrand = brandOrders.find((o) => o._id === byBrand.__brand._id);
      if (!isAvailableBrand) {
         const newBrandOrders = [...brandOrders, byBrand.__brand];
         const fOrderIsAvailable = byBrand.items.filter((it) => {
            return (
               it.__variant.options.stockStatus === EStockStatus.Stocking &&
               it.__variant.options.status === EStatus.enable &&
               it.__product?.options.status === EStatus.enable
            );
         });
         if (fOrderIsAvailable) {
            const newOrders = replaceAllDuplicateItem([...orders, ...fOrderIsAvailable], "_id");
            const cartIds = newOrders.map((it) => it._id);
            setState({
               cartIds,
               orders: newOrders,
               brandOrders: newBrandOrders,
               initSelectAll: false,
            });
            updateCartUsersAndVoucher({ cartIds });
         }
      } else {
         const newBrandOrders = brandOrders.filter((_brand) => _brand._id !== byBrand.__brand._id);
         const newOrders = orders.filter((order) => order.__brand._id !== byBrand.__brand._id);
         const cartIds = newOrders.map((it) => it._id);
         setState({
            cartIds,
            orders: newOrders,
            brandOrders: newBrandOrders,
            initSelectAll: false,
         });
         updateCartUsersAndVoucher({ cartIds });
      }
   }
   function handleAllOrderByBrand(byBrand: UserCartByBrand, cartItem: UserCart, newOrders: UserCart[]) {
      let newBrand = [...state.brandOrders];

      if (!state.carts) return newBrand;

      let isAllItemSelectOfBrand = false;

      const items = byBrand.items;
      const orderByBrandList = newOrders.filter((item) => item.__brand._id === byBrand.idBrand);
      if (orderByBrandList?.length >= items.length) {
         isAllItemSelectOfBrand = true;
      }

      if (isAllItemSelectOfBrand) {
         newBrand = [...state.brandOrders, byBrand.__brand];
      } else {
         const isCurrentBrandSelect = state.brandOrders.find((o) => o._id === byBrand.__brand._id);
         if (isCurrentBrandSelect) {
            newBrand = state.brandOrders.filter((brand) => brand._id !== byBrand.__brand._id);
         }
      }
      return newBrand;
   }
   function handleCheckOrderItem(byBrand: UserCartByBrand, cartItem: UserCart) {
      const { orders } = state;
      const isChecked = orders.find((it) => it._id === cartItem._id);
      if (isChecked) {
         const newOrders = orders.filter((order) => order._id !== cartItem._id);
         const newBrand = handleAllOrderByBrand(byBrand, cartItem, newOrders);
         const cartIds = newOrders.map((it) => it._id);
         setState({
            cartIds,
            orders: newOrders,
            brandOrders: newBrand,
            initSelectAll: false,
         });
         updateCartUsersAndVoucher({ cartIds });
      } else {
         const newOrders = [...orders, cartItem];
         const newBrand = handleAllOrderByBrand(byBrand, cartItem, newOrders);
         const cartIds = newOrders.map((it) => it._id);
         setState({
            cartIds,
            orders: newOrders,
            brandOrders: newBrand,
            initSelectAll: false,
         });
         updateCartUsersAndVoucher({ cartIds });
      }
   }
   function isProductChecked(id: string) {
      return state.orders.findIndex((item) => item._id === id) > -1;
   }
   async function handleRemoveProductFromCart(productId: string) {
      try {
         let { cartIds } = state;
         const isOrder = isProductChecked(productId);
         // uncheck order product
         if (isOrder) {
            const fOrders = state.orders.filter((item) => item._id !== productId);
            cartIds = fOrders.map((it) => it._id);
            setState({
               orders: fOrders,
               cartIds,
               initSelectAll: false,
            });
         }

         function removeProductItemFromCart(_array, _productId: string) {
            const tempCarts = JSON.parse(JSON.stringify(_array)) as UserCartByBrand[];
            const newCarts = tempCarts.map((item) => {
               const items = item.items.filter((sub) => {
                  return sub._id !== _productId;
               });
               if (items && items.length > 0) {
                  return {
                     ...item,
                     items,
                  };
               }
               return {
                  ...item,
                  items: [],
               };
            });
            return {
               newCarts: newCarts.filter((cart) => cart.items && cart.items.length > 0) as UserCartByBrand[],
               removeCart: newCarts.filter((cart) => !cart.items || cart.items.length === 0) as UserCartByBrand[],
            };
         }

         // remove product in carts state
         const { carts } = state;
         const { newCarts, removeCart } = removeProductItemFromCart(carts, productId);

         // remove brand orders in state
         let newBrandOrders = JSON.parse(JSON.stringify(state.brandOrders)) as Brand[];
         if (removeCart && removeCart.length > 0) {
            const removeCartIds = removeCart.map((item) => item.idBrand);
            newBrandOrders = newBrandOrders.filter((brand) => {
               return !removeCartIds.includes(brand._id);
            });
         }

         setState({
            carts: newCarts,
            brandOrders: newBrandOrders,
            initSelectAll: false,
         });
         UserCart.del(productId)
            .then((res) => {
               updateCartUsersAndVoucher({ cartIds });
            })
            .catch();
      } catch (error) {}
   }
   function isSelectAllProduct() {
      const total = getTotalProductAvailable();
      const currentSelect = state.orders.length;
      return total === currentSelect && total > 0 && currentSelect > 0;
   }
   function getTotalPriceAllProductAvailable(orders: UserCart[]) {
      let total = 0;
      if (orders && orders.length > 0) {
         orders.forEach((item) => {
            total +=
               +(item.__variant.info.priceAfterDiscount || item.__variant.info.price || 0) * +(item.info.quantity || 0);
         });
      }
      return total;
   }
   function getAllBrandsAndProductsAvailable(allUserCarts: UserCartByBrand[]) {
      const newBrandOrders: Brand[] = [];
      const newOrders: UserCart[] = [];
      for (let i = 0; i < allUserCarts.length; i++) {
         const item = allUserCarts[i];
         const fProductIsAvailable = item.items.filter(
            (it) =>
               it.__variant.options.stockStatus === EStockStatus.Stocking &&
               it.__variant.options.status === EStatus.enable &&
               it.__product?.options.status === EStatus.enable
         );
         if (fProductIsAvailable && fProductIsAvailable.length > 0) {
            newOrders.push(...fProductIsAvailable);
            newBrandOrders.push(item.__brand);
         }
      }
      const newDataReplaceOrders = replaceAllDuplicateItem(
         newOrders.filter((o) => !!o),
         "_id"
      );
      const newDataReplaceBrandOrders = replaceAllDuplicateItem(
         newBrandOrders.filter((o) => !!o),
         "_id"
      );
      const cartIds = newDataReplaceOrders.map((it) => it._id);
      return {
         orders: newDataReplaceOrders,
         brandOrders: newDataReplaceBrandOrders,
         cartIds,
         totalPrice: getTotalPriceAllProductAvailable(newDataReplaceOrders),
      };
   }
   function handleSelectAllProduct() {
      const isSelectAll = isSelectAllProduct();
      if (isSelectAll) {
         setState({
            orders: [],
            cartIds: [],
            brandOrders: [],
            initSelectAll: false,
         });
         updateCartUsersAndVoucher({
            cartIds: [],
         });
      } else {
         const { orders, brandOrders, cartIds } = getAllBrandsAndProductsAvailable(state.carts || []);
         setState({
            cartIds,
            orders,
            brandOrders,
            cartByBrandInteract: undefined,
            initSelectAll: false,
         });
         updateCartUsersAndVoucher({ cartIds });
      }
   }

   const { toggleBookmarkProduct } = useBookmark();

   const handleToggleBookmarkProduct = async (cartItem: UserCartByBrand, product: Product, isBookmark?: boolean) => {
      const _isCurrentUserBookmark = !!product?.__currentUserBookmark?._id;
      if (isBookmark === _isCurrentUserBookmark) return;

      toggleBookmarkProduct(product?._id, product?.__currentUserBookmark?._id).then(
         (res: UserBookmark | ModelError | null) => {
            if (!res) return;
            if (res instanceof ModelError) return;
            if (res._id) {
               product.setNewBookmark(res);
               setState({ carts: state.carts });
            } else {
               product.setNewBookmark(undefined);
               setState({ carts: state.carts });
            }
         }
      );
   };

   return (
      <Popover
         className={classNames(
            styles["popover-cart"],
            !state?.carts ? styles["loading"] : state?.carts.length <= 0 ? styles["empty"] : ""
         )}
         open={Boolean(anchorEl)}
         anchorEl={anchorEl}
         hideBackdrop
         anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
         }}
         transformOrigin={{
            vertical: "top",
            horizontal: 250,
         }}
      >
         <div
            className={styles["popover-cart-content"]}
            onMouseLeave={onClose}
            //
         >
            {cartStore.loading ? (
               <div className={styles["loading-card"]}>
                  <Loadingv2 />
               </div>
            ) : cartStore.error ? (
               <div className={styles["loading-card"]}>Có lỗi xảy ra. Vui lòng tải lại trang.</div>
            ) : state.carts.length <= 0 ? (
               renderEmptyCart()
            ) : (
               <div className={styles["product-list-by-cate"]}>
                  <div className={styles["wrap-list-product"]}>
                     {state.carts.map((cartItem, i) => {
                        // const voucherItem = state.shopVouchers[cartItem.__brand._id];
                        return (
                           <CartByBrandItem
                              key={cartItem._id + i}
                              cartItem={cartItem}
                              shopVoucherLoading={state.loading}
                              orders={state.orders}
                              ordersIds={state.cartIds}
                              // recommendVoucher={state.recommendVoucher}
                              // voucherSelect={voucherItem}
                              brandOrders={state.brandOrders}
                              outstockProductVariantId={state.outstockProductVariantId}
                              onUpdateItemCart={updateItemCart}
                              onCheckOrderByBrand={handleCheckOrderByBrand}
                              onCheckOrderItem={handleCheckOrderItem}
                              // onSelectVariant={handleShowDialogCharacteristic.bind(this)}
                              onRemoveProductFromCart={handleRemoveProductFromCart}
                              // onConfirmSelectVoucher={handleConfirmSelectVoucher.bind(this)}
                              toggleBookmarkProduct={handleToggleBookmarkProduct}
                           />
                        );
                     })}
                  </div>
                  <div className={styles["cart-footer"]}>
                     <div className="d-flex align-items-center justify-content-between px-2 py-1">
                        <div className="d-flex align-items-center">
                           <div className={styles["wrap-checkbox"]} onClick={() => handleSelectAllProduct()}>
                              <div
                                 className={`${
                                    isSelectAllProduct()
                                       ? classNames(styles["checkbox"], styles["checked"])
                                       : styles["checkbox"]
                                 } `}
                              >
                                 {/* {isCalculating && <CircularProgress size={12} />} */}
                              </div>
                           </div>
                           <span className="ms-2">Tất cả</span>
                        </div>

                        <div className="d-flex align-items-center">
                           <span className="me-2">Tổng cộng</span>
                           <span className="fs-18 fw-800 text-primary text-nowrap">
                              {state.initSelectAll
                                 ? currency(state.totalInitPrice)
                                 : currency(state.preCalculate?.total)}
                              <span>đ</span>
                           </span>
                        </div>
                     </div>
                     <Button className="mt-2" width="100%" onClick={() => router.push("/shop/gio-hang")}>
                        Xem giỏ hàng ({getTotalProductAvailable()})
                     </Button>
                  </div>
               </div>
            )}
         </div>
      </Popover>
   );
}
