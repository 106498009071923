/* eslint-disable max-len */
import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import classNames from "classnames";
import Collapse from "@mui/material/Collapse";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// Components
import { NextRouter, withRouter } from "next/router";
import icon_address from "../../../public/assets/img/icon/address.svg";
import icon_appstore from "../../../public/assets/img/icon/appdownload-appstore.svg";
import icon_googleplay from "../../../public/assets/img/icon/appdownload-googleplay.svg";
import icon_bct from "../../../public/assets/img/icon/bo-cong-thuong.svg";
import icon_email from "../../../public/assets/img/icon/email.svg";
// import icon_chat from "../../../public/assets/img/icon/icon_chat.svg";
import icon_fb from "../../../public/assets/img/icon/fb-icon.svg";
import icon_instar from "../../../public/assets/img/icon/instar-icon.svg";
import icon_phone from "../../../public/assets/img/icon/phone.svg";
import icon_youtube from "../../../public/assets/img/icon/youtube.svg";
import icon_tiktok from "../../../public/assets/img/icon/tiktok.svg";
import icon_pinterest from "../../../public/assets/img/icon/pinterest.png";
import LayoutContainer from "../Container";

interface IProps {
   className?: string;
   router: NextRouter;
   isMobile?: boolean;
}
class Footer extends React.Component<IProps> {
   private readonly _topBlocks = [
      {
         title: "Thông tin liên hệ",
         layout: "highlight",
         type: "address",
         links: [
            {
               icon: icon_phone,
               title: "084-884-7938",
               url: "tel:+84848847938",
               highlight: true,
               alt: "Số điện thoại",
               target: false,
            },
            {
               // icon: icon_chat,
               title: "Zalo",
               alt: "Chat Zalo",
               url: "https://zalo.me/1171954959320860843",
               iconType: "/assets/img/icon/zalo-icon.svg",
               target: false,
            },
            // {
            //    icon: icon_chat,
            //    title: "Messenger",
            //    alt: "Messenger",
            //    url: "https://www.messenger.com/t/649707212106005",
            //    iconType: "/assets/img/icon/facebook-icon.png",
            //    target: false
            // },
            {
               icon: icon_email,
               title: "info@spacet.vn",
               url: "mailto:info@spacet.vn",
               alt: "Địa chỉ email",
               target: false,
            },
            {
               icon: icon_address,
               title: "Lầu 5, Tòa nhà La Bonita, số 6-8 đường Nguyễn Gia Trí, Phường 25, Quận Bình Thạnh, Thành phố Hồ Chí Minh, Việt Nam",
               url: "https://www.google.com/maps/place/Space+T+Vietnam/@10.8069132,106.7155332,18z/data=!4m5!3m4!1s0x31752f53fec7893b:0x6b1190e531a8e8be!8m2!3d10.8069257!4d106.7161129?hl=vi-VN",
               alt: "Địa chỉ Space T",
               target: true,
            },
         ],
      },
      {
         title: "Social",
         type: "socials",
         layout: "continue_images",
         links: [
            {
               icon: icon_fb,
               title: "Facebook",
               url: "https://www.facebook.com/spacetvn",
               alt: "Space T Fanpage",
            },
            {
               icon: icon_tiktok,
               title: "Tiktok",
               url: "https://www.tiktok.com/@spacet_vn",
               alt: "Space T Tiktok",
            },
            {
               icon: icon_instar,
               title: "Instagram",
               url: "https://www.instagram.com/spacet_vn",
               alt: "Space T Instagram",
            },
            // {
            //     icon: icon_zalo,
            //     title: "",
            //     url: "https://zalo.me/1171954959320860843",
            //     alt: "Space T Zalo"
            // },
            {
               icon: icon_youtube,
               title: "Youtube",
               url: "https://www.youtube.com/SpaceT",
               alt: "Space T Youtube",
            },

            {
               icon: icon_pinterest,
               title: "Pinterest",
               url: "https://www.pinterest.com/2wt3u757irqbvxdzk53vqh2svshwzq",
               alt: "Space T Pinterest",
            },
         ],
      },
      {
         title: "Về Space T",
         layout: "normal",
         type: "info",
         className: "col-lg col-lg-2",
         links: [
            {
               title: "Giới thiệu",
               url: "/gioi-thieu-ve-space-t",
               alt: "Giới thiệu",
               // rel: "nofollow",
            },
            {
               title: "Space T tiếp thị liên kết",
               url: "/chuong-trinh-lien-ket",
               alt: "Space T tiếp thị liên kết",
               // rel: "nofollow",
            },
            {
               title: "Dành cho Nhà thầu",
               url: "https://partner.spacet.vn",
               alt: "Dành cho Nhà thầu",
               rel: "nofollow",
            },
            {
               title: "Dành cho Nhà cung cấp",
               url: "/chinh-sach/ban-hang-cung-space-t",
               alt: "Bán hàng cùng Space T",
            },
            {
               title: "Câu hỏi thường gặp",
               url: "/faq",
               alt: "Câu hỏi thường gặp",
            },
            {
               title: "Hướng dẫn thanh toán",
               url: "/chinh-sach/huong-dan-thanh-toan",
               alt: "Hướng dẫn thanh toán",
            },
            {
               title: "Chính sách Xu",
               url: "/chinh-sach/chinh-sach-diem",
               alt: "Chính sách Xu",
            },
            {
               title: "Chính sách Huy hiệu",
               url: "/chinh-sach/chinh-sach-huy-hieu",
               alt: "Chính sách Huy hiệu",
            },
         ],
      },
      {
         title: "Điều khoản và Chính sách",
         layout: "normal",
         type: "police",
         className: "col-lg col-lg-3",
         links: [
            {
               title: "Điều khoản sử dụng",
               url: "/chinh-sach/dieu-khoan-dich-vu",
               alt: "Điều khoản sử dụng",
            },
            // {
            //    title: "Chính sách thanh toán",
            //    url: "/chinh-sach/huong-dan-thanh-toan",
            //    alt: "Chính sách thanh toán"
            // },

            {
               title: "Chính sách giao hàng",
               url: "/chinh-sach/chinh-sach-giao-hang",
               alt: "Chính sách giao hàng",
            },
            {
               title: "Chính sách bảo hành",
               url: "/chinh-sach/chinh-sach-bao-hanh",
               alt: "Chính sách bảo hành",
            },
            {
               title: "Chính sách đổi trả",
               url: "/chinh-sach/chinh-sach-doi-tra",
               alt: "Chính sách đổi trả",
            },
            {
               title: "Chính sách bảo mật",
               url: "/chinh-sach/chinh-sach-bao-mat",
               alt: "Chính sách bảo mật",
            },
            {
               title: "Chính sách giải quyết khiếu nại",
               url: "/chinh-sach/chinh-sach-giai-quyet-khieu-nai",
               alt: "Chính sách giải quyết khiếu nại",
            },
            {
               title: "Tiêu chuẩn cộng đồng của Space T",
               url: "/chinh-sach/tieu-chuan-cong-dong-cua-spacet",
               alt: "Tiêu chuẩn cộng đồng của Space T",
            },
         ],
      },
      {
         title: "Tải ứng dụng Space T ngay",
         layout: "download",
         type: "dowload",
         links: [
            {
               icon: icon_appstore,
               title: "",
               url: "https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398",
               alt: "Space T Application",
            },
            {
               icon: icon_googleplay,
               title: "",
               url: "https://play.google.com/store/apps/details?id=com.spacet.mobile",
               alt: "Space T Application",
            },
         ],
      },
   ] as {
      title: string;
      layout: "normal" | "highlight" | "continue_images" | "download";
      className: string;
      type: string;
      links: {
         alt?: string;
         icon?: string;
         title?: string;
         url?: string;
         highlight?: boolean;
         target?: boolean;
         iconType?: string;
      }[];
      download: {
         alt?: string;
         icon?: string;
         title?: string;
         url?: string;
         highlight?: boolean;
         target?: boolean;
      }[];
   }[];

   constructor(props: any) {
      super(props);
      this.state = {};
   }
   render() {
      const contactInfo = (
         <ul className="list-unstyled mb-0">
            <li className="d-flex align-items-center">
               <div className="info-icon me-2">
                  <img
                     alt="Số điện thoại"
                     src={icon_phone.src}
                     decoding="async"
                     data-nimg={1}
                     loading="lazy"
                     style={{ color: "transparent" }}
                  />
               </div>
               <a className="highlight" href="tel:+84848847938" title="Số điện thoại" target="_self" rel="nofollow">
                  084-884-7938
               </a>
            </li>
            <li className="d-flex align-items-center">
               <a href="https://zalo.me/1171954959320860843" target="_blank" rel="nofollow noreferrer">
                  <img
                     className="img-icon me-2"
                     width={24}
                     height={24}
                     src="/assets/img/icon/zalo-icon.svg"
                     alt="Space T Chat"
                  />
                  <span>Zalo</span>
               </a>
            </li>
            <li className="d-flex align-items-center">
               <div className="info-icon me-2">
                  <img
                     alt="Địa chỉ email"
                     src={icon_email.src}
                     decoding="async"
                     data-nimg={1}
                     loading="lazy"
                     style={{ color: "transparent" }}
                  />
               </div>
               <a
                  className="no-highlight"
                  href="mailto:info@spacet.vn"
                  title="Địa chỉ email"
                  target="_self"
                  rel="nofollow"
               >
                  info@spacet.vn
               </a>
            </li>
            <li className="d-flex ">
               <div className="info-icon me-2">
                  <img
                     alt="Địa chỉ Space T"
                     src={icon_address.src}
                     decoding="async"
                     data-nimg={1}
                     loading="lazy"
                     style={{ color: "transparent" }}
                  />
               </div>
               <a
                  className="no-highlight"
                  href="https://www.google.com/maps/place/Space+T+Vietnam/@10.8069132,106.7155332,18z/data=!4m5!3m4!1s0x31752f53fec7893b:0x6b1190e531a8e8be!8m2!3d10.8069257!4d106.7161129?hl=vi-VN"
                  title="Địa chỉ Space T"
                  target="_self"
                  rel="nofollow"
               >
                  Lầu 5, Tòa nhà La Bonita, số 6-8 đường Nguyễn Gia Trí, Phường 25, Quận Bình Thạnh, Thành phố Hồ Chí
                  Minh, Việt Nam
               </a>
            </li>
         </ul>
      );
      const spacetInfo = (
         <ul className="list-unstyled mb-0" style={{ maxWidth: "258px" }}>
            <li>
               <a title="Giới thiệu" href="/gioi-thieu-ve-space-t">
                  Giới thiệu
               </a>
            </li>
            <li>
               <a title="Tiếp thị liên kết" href="/chuong-trinh-lien-ket">
                  Tiếp thị liên kết
               </a>
            </li>
            {/* <li>
               <a title="Dành cho Nhà thầu" href="https://partner.spacet.vn" rel="nofollow">
                  Dành cho Nhà thầu
               </a>
            </li> */}
            <li>
               <a title="Dành cho Nhà cung cấp" href="/chinh-sach/ban-hang-cung-space-t">
                  Dành cho Nhà cung cấp
               </a>
            </li>
            <li>
               <a title="Câu hỏi thường gặp" href="/faq">
                  Câu hỏi thường gặp
               </a>
            </li>
            <li>
               <a title="Hướng dẫn thanh toán" href="/chinh-sach/huong-dan-thanh-toan">
                  Hướng dẫn thanh toán
               </a>
            </li>
            <li>
               <a title="Chính sách Xu" href="/chinh-sach/chinh-sach-diem">
                  Chính sách Xu
               </a>
            </li>
            <li>
               <a title="Chính sách Huy hiệu" href="/chinh-sach/chinh-sach-huy-hieu">
                  Chính sách Huy hiệu
               </a>
            </li>
         </ul>
      );
      const termAndPolicy = (
         <ul className="list-unstyled mb-0" style={{ maxWidth: "258px" }}>
            <li>
               <a title="Điều khoản sử dụng" href="/chinh-sach/dieu-khoan-dich-vu">
                  Điều khoản sử dụng
               </a>
            </li>
            <li>
               <a title="Chính sách giao hàng" href="/chinh-sach/chinh-sach-giao-hang">
                  Chính sách giao hàng
               </a>
            </li>
            <li>
               <a title="Chính sách bảo hành" href="/chinh-sach/chinh-sach-bao-hanh">
                  Chính sách bảo hành
               </a>
            </li>
            <li>
               <a title="Chính sách đổi trả" href="/chinh-sach/chinh-sach-doi-tra">
                  Chính sách đổi trả
               </a>
            </li>
            <li>
               <a title="Chính sách bảo mật" href="/chinh-sach/chinh-sach-bao-mat">
                  Chính sách bảo mật
               </a>
            </li>
            <li>
               <a title="Chính sách giải quyết khiếu nại" href="/chinh-sach/chinh-sach-giai-quyet-khieu-nai">
                  Chính sách giải quyết khiếu nại
               </a>
            </li>
            <li>
               <a title="Tiêu chuẩn cộng đồng của Space T" href="/chinh-sach/tieu-chuan-cong-dong-cua-spacet">
                  Tiêu chuẩn cộng đồng của Space T
               </a>
            </li>
         </ul>
      );
      const followList = (
         <ul className=" list-unstyled socials">
            <li className="socials-item ">
               <a
                  href="https://www.facebook.com/spacetvn"
                  className="d-flex"
                  target="_blank"
                  rel="nofollow noreferrer"
                  title="Space T Fanpage"
               >
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M24.2988 12.5004C24.2988 18.5612 19.8062 23.5717 13.97 24.3848C13.4243 24.4605 12.866 24.5 12.2992 24.5C11.6451 24.5 11.0027 24.4479 10.3771 24.347C4.66277 23.4271 0.298828 18.4729 0.298828 12.5004C0.298828 5.87295 5.67178 0.5 12.2984 0.5C18.925 0.5 24.2988 5.87295 24.2988 12.5004Z"
                        fill="#1877F2"
                     />
                     <path
                        d="M13.9699 10.1351V12.7493H17.2037L16.6917 16.2707H13.9699V24.384C13.4242 24.4596 12.8659 24.4992 12.2991 24.4992C11.645 24.4992 11.0026 24.447 10.377 24.3461V16.2707H7.39453V12.7493H10.377V9.55077C10.377 7.56639 11.9855 5.95703 13.9707 5.95703V5.95871C13.9766 5.95871 13.9816 5.95703 13.9875 5.95703H17.2046V9.00254H15.1025C14.4777 9.00254 13.9707 9.50957 13.9707 10.1343L13.9699 10.1351Z"
                        fill="white"
                     />
                  </svg>
                  <div className="socials-label">Facebook</div>
               </a>
            </li>
            <li className="socials-item ">
               <a
                  href="https://www.tiktok.com/@spacet_vn"
                  className="d-flex"
                  target="_blank"
                  rel="nofollow noreferrer"
                  title="Space T Tiktok"
               >
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M12.3226 0.5H12.268C5.6576 0.5 0.298828 5.86035 0.298828 12.4727V12.5273C0.298828 19.1397 5.6576 24.5 12.268 24.5H12.3226C18.933 24.5 24.2918 19.1397 24.2918 12.5273V12.4727C24.2918 5.86035 18.933 0.5 12.3226 0.5Z"
                        fill="black"
                     />
                     <path
                        d="M18.3782 10.0316V12.2976C17.9823 12.2589 17.4678 12.169 16.8987 11.9604C16.1557 11.688 15.6026 11.3155 15.2403 11.0271V15.6071L15.231 15.5928C15.2369 15.6837 15.2403 15.7761 15.2403 15.8695C15.2403 18.1439 13.3901 19.9955 11.1155 19.9955C8.84086 19.9955 6.99072 18.1439 6.99072 15.8695C6.99072 13.595 8.84086 11.7427 11.1155 11.7427C11.3382 11.7427 11.5568 11.7603 11.7703 11.7948V14.028C11.5652 13.9549 11.345 13.9154 11.1155 13.9154C10.0387 13.9154 9.16197 14.7915 9.16197 15.8695C9.16197 16.9474 10.0387 17.8236 11.1155 17.8236C12.1923 17.8236 13.069 16.9466 13.069 15.8695C13.069 15.8291 13.0682 15.7888 13.0657 15.7484V6.84814H15.3294C15.3378 7.03986 15.3453 7.23325 15.3537 7.42496C15.3689 7.80249 15.5034 8.16489 15.7379 8.46171C16.0128 8.81066 16.4188 9.21594 16.9887 9.53966C17.5225 9.84236 18.0235 9.97185 18.3782 10.0332V10.0316Z"
                        fill="#FF004F"
                     />
                     <path
                        d="M17.5999 8.19193V10.458C17.2039 10.4193 16.6895 10.3293 16.1204 10.1208C15.3773 9.84837 14.8242 9.47588 14.4619 9.18748V13.7675L14.4527 13.7532C14.4586 13.844 14.4619 13.9365 14.4619 14.0299C14.4619 16.3043 12.6118 18.1558 10.3372 18.1558C8.06254 18.1558 6.2124 16.3043 6.2124 14.0299C6.2124 11.7554 8.06254 9.90303 10.3372 9.90303C10.5599 9.90303 10.7785 9.92069 10.992 9.95516V12.1884C10.7869 12.1153 10.5667 12.0757 10.3372 12.0757C9.26038 12.0757 8.38364 12.9519 8.38364 14.0299C8.38364 15.1078 9.26038 15.984 10.3372 15.984C11.414 15.984 12.2907 15.107 12.2907 14.0299C12.2907 13.9895 12.2899 13.9491 12.2873 13.9088V5.00684H14.551C14.5594 5.19855 14.567 5.39194 14.5754 5.58365C14.5906 5.96119 14.725 6.32359 14.9596 6.6204C15.2344 6.96935 15.6404 7.37463 16.2104 7.69835C16.7441 8.00021 17.2451 8.13054 17.5999 8.19193Z"
                        fill="#00F7EF"
                     />
                     <path
                        d="M18.095 9.18243V11.4485C17.6991 11.4098 17.1846 11.3198 16.6155 11.1113C15.8725 10.8389 15.3194 10.4664 14.9571 10.178V14.758L14.9478 14.7437C14.9537 14.8345 14.9571 14.927 14.9571 15.0204C14.9571 17.2948 13.1069 19.1463 10.8323 19.1463C8.55766 19.1463 6.70752 17.2948 6.70752 15.0204C6.70752 12.7459 8.55766 10.8935 10.8323 10.8935C11.055 10.8935 11.2736 10.9112 11.4871 10.9457V13.1789C11.282 13.1058 11.0618 13.0663 10.8323 13.0663C9.7555 13.0663 8.87876 13.9424 8.87876 15.0204C8.87876 16.0983 9.7555 16.9745 10.8323 16.9745C11.9091 16.9745 12.7858 16.0975 12.7858 15.0204C12.7858 14.98 12.785 14.9396 12.7825 14.8993V5.99902H15.0462C15.0546 6.19073 15.0621 6.38413 15.0705 6.57584C15.0857 6.95337 15.2202 7.31577 15.4547 7.61259C15.7296 7.96154 16.1356 8.36682 16.7055 8.69054C17.2393 8.9924 17.7403 9.12273 18.095 9.18411V9.18243Z"
                        fill="white"
                     />
                  </svg>
                  <div className="socials-label">Tiktok</div>
               </a>
            </li>
            <li className="socials-item ">
               <a
                  href="https://www.instagram.com/spacet_vn"
                  className="d-flex"
                  target="_blank"
                  rel="nofollow noreferrer"
                  title="Space T Instagram"
               >
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M12.3226 0.5H12.268C5.6576 0.5 0.298828 5.86035 0.298828 12.4727V12.5273C0.298828 19.1397 5.6576 24.5 12.268 24.5H12.3226C18.933 24.5 24.2918 19.1397 24.2918 12.5273V12.4727C24.2918 5.86035 18.933 0.5 12.3226 0.5Z"
                        fill="url(#paint0_linear_6709_476024)"
                     />
                     <path
                        d="M16.0079 5.38281H8.58386C6.53283 5.38281 4.86426 7.05187 4.86426 9.10351V15.8975C4.86426 17.9491 6.53283 19.6182 8.58386 19.6182H16.0079C18.059 19.6182 19.7276 17.9491 19.7276 15.8975V9.10351C19.7276 7.05187 18.059 5.38281 16.0079 5.38281ZM6.17642 9.10351C6.17642 7.77583 7.25657 6.69536 8.58386 6.69536H16.0079C17.3352 6.69536 18.4154 7.77583 18.4154 9.10351V15.8975C18.4154 17.2252 17.3352 18.3056 16.0079 18.3056H8.58386C7.25657 18.3056 6.17642 17.2252 6.17642 15.8975V9.10351Z"
                        fill="white"
                     />
                     <path
                        d="M12.2958 15.9606C14.2031 15.9606 15.7557 14.4084 15.7557 12.4997C15.7557 10.591 14.2039 9.03882 12.2958 9.03882C10.3877 9.03882 8.83594 10.591 8.83594 12.4997C8.83594 14.4084 10.3877 15.9606 12.2958 15.9606ZM12.2958 10.3522C13.4802 10.3522 14.4435 11.3158 14.4435 12.5005C14.4435 13.6853 13.4802 14.6489 12.2958 14.6489C11.1114 14.6489 10.1481 13.6853 10.1481 12.5005C10.1481 11.3158 11.1114 10.3522 12.2958 10.3522Z"
                        fill="white"
                     />
                     <path
                        d="M16.0763 9.59789C16.5899 9.59789 17.0085 9.17999 17.0085 8.6654C17.0085 8.15081 16.5907 7.73291 16.0763 7.73291C15.5618 7.73291 15.144 8.15081 15.144 8.6654C15.144 9.17999 15.5618 9.59789 16.0763 9.59789Z"
                        fill="white"
                     />
                     <defs>
                        <linearGradient
                           id="paint0_linear_6709_476024"
                           x1="3.80408"
                           y1="20.9937"
                           x2="20.7915"
                           y2="4.01213"
                           gradientUnits="userSpaceOnUse"
                        >
                           <stop stopColor="#FAAD4F" />
                           <stop offset="0.35" stopColor="#DD2A7B" />
                           <stop offset="0.62" stopColor="#9537B0" />
                           <stop offset="1" stopColor="#515BD4" />
                        </linearGradient>
                     </defs>
                  </svg>
                  <div className="socials-label">Instagram</div>
               </a>
            </li>
            <li className="socials-item ">
               <a
                  href="https://www.youtube.com/SpaceT"
                  className="d-flex"
                  target="_blank"
                  rel="nofollow noreferrer"
                  title="Space T Youtube"
               >
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M24.2918 12.5C24.2918 5.87258 18.9208 0.5 12.2953 0.5C5.66983 0.5 0.298828 5.87258 0.298828 12.5C0.298828 19.1274 5.66983 24.5 12.2953 24.5C18.9208 24.5 24.2918 19.1274 24.2918 12.5Z"
                        fill="#FF0209"
                     />
                     <path
                        d="M17.3876 17.8149C17.0302 17.8537 16.6644 17.8604 16.3137 17.8596C13.5782 17.8571 10.8427 17.8545 8.1081 17.8529C7.24742 17.8529 6.31002 17.825 5.64491 17.2778C4.8955 16.6597 4.73955 15.5879 4.67886 14.6182C4.59456 13.2893 4.58782 11.9562 4.65694 10.6264C4.69487 9.89614 4.76147 9.1482 5.0759 8.48711C5.30182 8.01237 5.67863 7.58569 6.16082 7.3614C6.7214 7.10084 7.30811 7.14722 7.91169 7.14637C9.35656 7.14469 10.8014 7.14384 12.2463 7.14216C13.7485 7.14047 15.2515 7.13963 16.7537 7.13794C17.4635 7.13794 18.2256 7.15227 18.8047 7.56293C19.5524 8.09248 19.7556 9.10013 19.8491 10.0117C20.022 11.6905 20.0245 13.3871 19.8559 15.066C19.7859 15.7583 19.6696 16.4902 19.2135 17.0155C18.7617 17.5366 18.0915 17.7373 17.3885 17.8141L17.3876 17.8149Z"
                        fill="white"
                     />
                     <path d="M14.7417 12.5L10.665 10.1458V14.8543L14.7417 12.5Z" fill="#FF0209" />
                  </svg>
                  <div className="socials-label">Youtube</div>
               </a>
            </li>
            <li className="socials-item ">
               <a
                  href="https://www.pinterest.com/2wt3u757irqbvxdzk53vqh2svshwzq"
                  className="d-flex"
                  target="_blank"
                  rel="nofollow noreferrer"
                  title="Space T Pinterest"
               >
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M12.3226 0.5H12.268C5.6576 0.5 0.298828 5.86035 0.298828 12.4727V12.5273C0.298828 19.1397 5.6576 24.5 12.268 24.5H12.3226C18.933 24.5 24.2918 19.1397 24.2918 12.5273V12.4727C24.2918 5.86035 18.933 0.5 12.3226 0.5Z"
                        fill="#FF0000"
                     />
                     <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.2004 15.7813C11.1861 15.8309 11.1726 15.873 11.1617 15.915C10.5271 18.4022 10.4565 18.9546 9.80416 20.1099C9.49314 20.659 9.14262 21.1795 8.7551 21.6772C8.71139 21.7336 8.6702 21.8059 8.58278 21.7882C8.48695 21.7681 8.47939 21.6814 8.4693 21.6049C8.36507 20.849 8.30707 20.0914 8.33229 19.3279C8.36507 18.3341 8.4878 17.9927 9.76885 12.6046C9.78735 12.5222 9.76633 12.4541 9.73943 12.3801C9.43262 11.5527 9.37209 10.7127 9.64024 9.86181C10.2202 8.02374 12.3032 7.88332 12.6672 9.39935C12.8916 10.3369 12.2982 11.5645 11.8426 13.379C11.4652 14.8757 13.227 15.9402 14.7325 14.8471C16.1212 13.8398 16.66 11.4249 16.5575 9.71298C16.3557 6.30003 12.6142 5.56261 10.2413 6.66158C7.52028 7.92032 6.90161 11.2938 8.13055 12.8358C8.28606 13.0318 8.57942 13.152 8.52814 13.3504C8.44913 13.659 8.37936 13.9701 8.29362 14.277C8.22973 14.5057 7.86576 14.589 7.6346 14.4948C7.18152 14.3115 6.80409 14.0231 6.49812 13.6431C5.45495 12.3507 5.15654 9.79454 6.53595 7.63023C8.06414 5.233 10.907 4.26268 13.5027 4.55697C16.6028 4.90928 18.5623 7.02819 18.9288 9.43214C19.096 10.5269 18.9758 13.2268 17.4392 15.1355C15.6715 17.3284 12.8059 17.4739 11.4837 16.1277C11.3819 16.0243 11.3004 15.9041 11.2004 15.7805V15.7813Z"
                        fill="white"
                     />
                  </svg>
                  <div className="socials-label">Pinterest</div>
               </a>
            </li>
         </ul>
      );
      const paymentMethod = (
         <div className="subfooter-grid">
            <div className="subfooter-block delevery-method">
               <div className="title ">Delivery method</div>
               <ul>
                  <li>
                     <a>
                        <img src="/img/delivery/ghtk.png" alt="" />
                     </a>
                  </li>
                  <li>
                     <a>
                        <img src="/img/delivery/j&t.png" alt="" />
                     </a>
                  </li>
                  <li>
                     <a>
                        <img src="/img/delivery/viettelpost.png" alt="" />
                     </a>
                  </li>
               </ul>
            </div>
            <div className="subfooter-block delevery-method">
               <div className="title">payment method</div>
               <ul>
                  <li>
                     <a>
                        <img src="/img/payment/momo.png" alt="" />
                     </a>
                  </li>
                  <li>
                     <a>
                        <img src="/img/payment/banking.png" alt="" />
                     </a>
                  </li>
                  <li>
                     <a>
                        <img src="/img/payment/cash.png" alt="" />
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      );
      return (
         <footer
            id="footer"
            className={classNames(
               this.props.className,
               ["spacet-main-footer"],
               [
                  this.props.router.pathname === "/" || this.props.router.pathname === "/shop"
                     ? ["custom-border-footer"]
                     : "",
               ]
            )}
         >
            <LayoutContainer>
               <div className="top">
                  <div className="footer-grid gx-0">
                     <div className="block spacet-info block-collapse">
                        <div className="d-block d-lg-none">
                           <CollapseInfo label="THÔNG TIN LIÊN HỆ">{contactInfo}</CollapseInfo>
                        </div>
                        <div className="d-none d-lg-block ">
                           <h4 className="title_footer">Thông tin liên hệ</h4>
                           {contactInfo}
                        </div>
                     </div>
                     <div className="block block-collapse">
                        <div className="d-block d-lg-none">
                           <CollapseInfo label="Về Space T">{spacetInfo}</CollapseInfo>
                        </div>
                        <div className="d-none d-lg-block ">
                           <h4 className="title_footer">Về Space T </h4>
                           {spacetInfo}
                        </div>
                     </div>
                     <div className="block block-collapse">
                        <div className="d-block d-lg-none">
                           <CollapseInfo label="Điều khoản và Chính sách">{termAndPolicy}</CollapseInfo>
                        </div>
                        <div className="d-none d-lg-block ">
                           <h4 className="title_footer">Điều khoản và Chính sách </h4>
                           {termAndPolicy}
                        </div>
                     </div>
                     {this.props.isMobile && <div className="">{paymentMethod}</div>}
                     <div className="block spacet-social block-continue-images">
                        <h4 className="title_footer">follow us</h4>
                        {followList}
                     </div>
                     <div className="block block-download">
                        <h4 className="title_footer">Tải ứng dụng Space T ngay </h4>
                        <div className="row g-3 d-flex">
                           <div className="col-4 col-lg-5">
                              <div className="qr-code">
                                 <img
                                    alt="QR Code Space T"
                                    srcSet="/_next/image?url=%2Fassets%2Fimg%2Ficon%2FQR_Code_spacet.png&w=96&q=75 1x, /_next/image?url=%2Fassets%2Fimg%2Ficon%2FQR_Code_spacet.png&w=256&q=75 2x"
                                    src="/_next/image?url=%2Fassets%2Fimg%2Ficon%2FQR_Code_spacet.png&w=256&q=75"
                                    width={94}
                                    height={94}
                                    decoding="async"
                                    data-nimg={1}
                                    loading="lazy"
                                    style={{ color: "transparent" }}
                                 />
                              </div>
                           </div>
                           <div className="col-5 col-lg-6">
                              <div className="wrap-icon-download">
                                 <a
                                    href="https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                 >
                                    <img
                                       alt="Space T Application"
                                       srcSet="/_next/static/media/appdownload-appstore.65cf80c7.svg 1x, /_next/static/media/appdownload-appstore.65cf80c7.svg 2x"
                                       src="/_next/static/media/appdownload-appstore.65cf80c7.svg"
                                       width={94}
                                       height={94}
                                       decoding="async"
                                       data-nimg={1}
                                       loading="lazy"
                                       style={{ color: "transparent" }}
                                    />
                                 </a>
                                 <a
                                    href="https://play.google.com/store/apps/details?id=com.spacet.mobile"
                                    target="_blank"
                                    rel="nofollow noreferrer"
                                 >
                                    <img
                                       alt="Space T Application"
                                       srcSet="/_next/static/media/appdownload-googleplay.f1cdb054.svg 1x, /_next/static/media/appdownload-googleplay.f1cdb054.svg 2x"
                                       src="/_next/static/media/appdownload-googleplay.f1cdb054.svg"
                                       width={94}
                                       height={94}
                                       decoding="async"
                                       data-nimg={1}
                                       loading="lazy"
                                       style={{ color: "transparent" }}
                                    />
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {!this.props.isMobile && paymentMethod}
               </div>
               <div className="bottom">
                  <div className="left">
                     <h4 className="title_company">Công ty TNHH Space T</h4>
                     <p className="description">
                        Thành lập và hoạt động theo Giấy chứng nhận đăng ký doanh nghiệp số: 0315679882 do Sở Kế hoạch
                        và Đầu tư TP Hồ Chí Minh cấp, đăng ký lần đầu ngày 14/05/2019
                     </p>
                     <p className="description">Mã số thuế: 0315679882</p>
                     <p className="description">Copyright © 2021 SPACE T.</p>
                  </div>
                  <Link title="Bộ công thương" href="http://online.gov.vn/Home/WebDetails/64962" rel="nofollow">
                     <Image src={icon_bct} alt="Đã thông báo bộ công thương" />
                  </Link>
               </div>
            </LayoutContainer>
         </footer>
      );
   }
}

export default withRouter(Footer);

const CollapseInfo = ({ label, children }: { label: string; children: React.ReactElement }) => {
   const [open, setOpen] = useState(false);
   const toggleCollapse = () => {
      setOpen(!open);
   };
   return (
      <>
         <div className="title title-collapse" onClick={toggleCollapse} style={{ marginBottom: 0 }}>
            {label}
            {open ? (
               <RemoveIcon fontSize="small" style={{ fontSize: 14 }} />
            ) : (
               <AddIcon fontSize="small" style={{ fontSize: 14 }} />
            )}
         </div>
         <Collapse className="collapse-content" in={open} timeout="auto" unmountOnExit>
            {children}
         </Collapse>
      </>
   );
};
