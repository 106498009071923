/* eslint-disable max-len */
import { Dialog, Drawer } from "@mui/material";
import Slider from "react-slick";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { isMobile } from "react-device-detect";
import { openModalPost } from "@State/action/socialFeed";
import { RootState } from "@State";
import { ESocialFeedType } from "@Models/socialFeed.model";
import User from "@Models/user.model";
import Order, { EOrderStatus, IOrder } from "@Models/order.model";
import { ModelError, Paginate } from "@Models/model";
import { dotNum } from "@Utils";
import ReviewProductIcon from "./review-product-icon.png";
import CreatePostIcon from "./create-post-icon.png";

interface IProps {
   visible?: boolean;
   onClose?: () => void;
}

const ModalReceiveCoin = ({ visible, onClose }: IProps) => {
   const disaptch = useDispatch();
   const router = useRouter();
   const { points } = useSelector((state: RootState) => state.auth);
   const [orderList, setOrderList] = useState<Paginate<Order, IOrder>>();
   const [sliderData] = useState([
      {
         imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/receive-coin-banner.webp",
         imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/receive-coin-banner.webp",
         title: "Tích luỹ xu mua sắm",
         url: "/",
      },
   ]);

   const settingsSlider = {
      arrows: false,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
   };

   const handleClose = () => {
      onClose?.();
   };

   useEffect(() => {
      User.getOrderPaginate({
         ["limit"]: 1,
         ["page"]: 1,
         sort: "desc",
         sortBy: "createdAt",
         ["status"]: EOrderStatus.successfullyDelivered,
      }).then((res) => {
         if (res instanceof ModelError) return;
         setOrderList(res);
      });
   }, []);

   const isNotBuyProduct = !orderList || (orderList && orderList?.totalDocs <= 0);

   return (
      <Layout visible={visible} onClose={handleClose}>
         <div className="row gx-0">
            {!isMobile && (
               <div className="col-md-5_7">
                  <div className="modal-left">
                     <Slider {...settingsSlider}>
                        {sliderData.map((banner, i) => {
                           return (
                              <div className="slider-item" key={i}>
                                 <picture>
                                    <source media="(min-width: 768px)" srcSet={banner.imgPc ?? ""} />
                                    <source media="(max-width: 767px)" srcSet={banner.imgMb ?? ""} />
                                    <img
                                       height={"100%"}
                                       width="100%"
                                       title={banner.title ?? ""}
                                       alt={banner.title ?? ""}
                                       src={banner.imgPc ?? ""}
                                    />
                                 </picture>
                              </div>
                           );
                        })}
                     </Slider>
                  </div>
               </div>
            )}
            <div className="col-12 col-md-6_3">
               <div className="modal-right">
                  <div className="current-coin">
                     <span className="name">Coin đang có</span>
                     <div className="coin-wrap">
                        <span className="value">{points?.useablePoint ? dotNum(points?.useablePoint) : 0}</span>
                        <img className="coin-icon" src="/assets/img/home/coin.png" alt=""></img>
                     </div>
                  </div>
                  <div className="br-link">
                     <img src="/assets/img/icon/hr-bottom.png" alt="" />
                  </div>
                  <div className="list-mission">
                     <div className="mission-item">
                        <div className="mission-item__content">
                           <div className="mission-item__left">
                              <div className="b-icon">
                                 <img src={CreatePostIcon.src} alt="" />
                              </div>
                              <svg
                                 className="space-icon"
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="2"
                                 height="24"
                                 viewBox="0 0 2 24"
                                 fill="none"
                              >
                                 <path d="M1 0V24" stroke="black" strokeOpacity="0.1" />
                              </svg>
                              <div>
                                 <span className="item-title">Tạo bài đăng</span>
                                 <div className="d-flex align-items-center">
                                    <span className="plus">+</span>
                                    <img className="coin-icon" src="/assets/img/home/coin.png" alt=""></img>
                                    <span className="coin-value">5.000</span>
                                 </div>
                              </div>
                           </div>
                           <div
                              className="mission-item__right"
                              onClick={async () => {
                                 handleClose();
                                 if (!router.asPath.includes("/feed")) {
                                    await router.push("/feed");
                                 }
                                 disaptch(
                                    openModalPost({
                                       visible: true,
                                       type: ESocialFeedType.socialFeed,
                                       showFirstHashtag: true,
                                    })
                                 );
                              }}
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="10"
                                 height="18"
                                 viewBox="0 0 10 18"
                                 fill="none"
                              >
                                 <path
                                    d="M9.5 9C9.5 9.10574 9.48022 9.20487 9.44066 9.2974C9.4011 9.38992 9.33846 9.47584 9.25275 9.55514L1.81538 16.772C1.67033 16.924 1.48901 17 1.27143 17C1.12637 17 0.997802 16.967 0.885715 16.9009C0.767033 16.8348 0.674726 16.7423 0.608791 16.6233C0.536263 16.5109 0.5 16.3788 0.5 16.2268C0.5 16.0219 0.572527 15.8401 0.717583 15.6815L7.62088 9L0.717583 2.30855C0.572527 2.15655 0.5 1.9748 0.5 1.76332C0.5 1.61793 0.536263 1.48575 0.608791 1.36679C0.674726 1.24783 0.767033 1.15861 0.885715 1.09913C0.997802 1.03304 1.12637 1 1.27143 1C1.48901 1 1.67033 1.0727 1.81538 1.21809L9.25275 8.43494C9.33846 8.51425 9.4011 8.60017 9.44066 8.69269C9.48022 8.78521 9.5 8.88765 9.5 9Z"
                                    fill="white"
                                    stroke="white"
                                 />
                              </svg>
                           </div>
                        </div>
                     </div>
                     <div className="mission-item">
                        <div className="mission-item__content">
                           <div className="mission-item__left">
                              <div className="b-icon">
                                 <img src={ReviewProductIcon.src} alt="" />
                              </div>
                              <svg
                                 className="space-icon"
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="2"
                                 height="24"
                                 viewBox="0 0 2 24"
                                 fill="none"
                              >
                                 <path d="M1 0V24" stroke="black" strokeOpacity="0.1" />
                              </svg>
                              <div>
                                 <span className="item-title">Đánh giá sản phẩm đã mua</span>
                                 <div className="d-flex align-items-center">
                                    <span className="plus">+</span>
                                    <img className="coin-icon" src="/assets/img/home/coin.png" alt=""></img>
                                    <span className="coin-value">1.000</span>
                                 </div>
                              </div>
                           </div>
                           <div
                              className={`mission-item__right ${isNotBuyProduct ? "disabled" : ""}`}
                              onClick={() => {
                                 if (isNotBuyProduct) return;
                                 handleClose();
                                 router.push("/tai-khoan/don-hang?type=product");
                              }}
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="10"
                                 height="18"
                                 viewBox="0 0 10 18"
                                 fill="none"
                              >
                                 <path
                                    d="M9.5 9C9.5 9.10574 9.48022 9.20487 9.44066 9.2974C9.4011 9.38992 9.33846 9.47584 9.25275 9.55514L1.81538 16.772C1.67033 16.924 1.48901 17 1.27143 17C1.12637 17 0.997802 16.967 0.885715 16.9009C0.767033 16.8348 0.674726 16.7423 0.608791 16.6233C0.536263 16.5109 0.5 16.3788 0.5 16.2268C0.5 16.0219 0.572527 15.8401 0.717583 15.6815L7.62088 9L0.717583 2.30855C0.572527 2.15655 0.5 1.9748 0.5 1.76332C0.5 1.61793 0.536263 1.48575 0.608791 1.36679C0.674726 1.24783 0.767033 1.15861 0.885715 1.09913C0.997802 1.03304 1.12637 1 1.27143 1C1.48901 1 1.67033 1.0727 1.81538 1.21809L9.25275 8.43494C9.33846 8.51425 9.4011 8.60017 9.44066 8.69269C9.48022 8.78521 9.5 8.88765 9.5 9Z"
                                    fill="white"
                                    stroke="white"
                                 />
                              </svg>
                           </div>
                        </div>
                        {isNotBuyProduct ? (
                           <div className={`mission-item__noproduct `}>
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                              >
                                 <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.33337 1.33203H10.3867C12.5958 1.33203 14.3867 3.12289 14.3867 5.33203V10.3854C14.3867 12.5945 12.5958 14.3854 10.3867 14.3854H5.33337C3.12424 14.3854 1.33337 12.5945 1.33337 10.3854V5.33203C1.33337 3.12289 3.12424 1.33203 5.33337 1.33203ZM10.3867 13.3854C12.042 13.3817 13.383 12.0407 13.3867 10.3854V5.33203C13.383 3.6767 12.042 2.3357 10.3867 2.33203H5.33337C3.67804 2.3357 2.33704 3.6767 2.33337 5.33203V10.3854C2.33704 12.0407 3.67804 13.3817 5.33337 13.3854H10.3867Z"
                                    fill="#BFA01D"
                                 />
                                 <path
                                    d="M7.86004 5.3587C7.5854 5.36229 7.36363 5.58406 7.36004 5.8587C7.36002 6.13594 7.58282 6.36172 7.86004 6.36537C7.99438 6.36723 8.12366 6.31423 8.21803 6.2186C8.3124 6.12297 8.36368 5.993 8.36004 5.8587C8.36004 5.58256 8.13618 5.3587 7.86004 5.3587Z"
                                    fill="#BFA01D"
                                 />
                                 <path
                                    d="M7.86004 7.2787C7.5839 7.2787 7.36004 7.50256 7.36004 7.7787V9.8587C7.36004 10.1348 7.5839 10.3587 7.86004 10.3587C8.13618 10.3587 8.36004 10.1348 8.36004 9.8587V7.79203C8.36555 7.6566 8.31509 7.52486 8.22051 7.42776C8.12594 7.33066 7.99557 7.27675 7.86004 7.2787Z"
                                    fill="#BFA01D"
                                 />
                              </svg>
                              Bạn chưa mua bất kỳ sản phẩm nào
                           </div>
                        ) : (
                           ""
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Layout>
   );
};

function Layout({
   visible,
   fullHeight,
   onClose,
   children,
   ...props
}: {
   visible?: boolean;
   fullHeight?: boolean;
   onClose: () => void;
   children: ReactNode;
}) {
   if (typeof window !== "undefined" && window?.innerWidth > 768) {
      return (
         <Dialog open={Boolean(visible)} onClose={onClose} className={`modal-receive`} {...props}>
            {!isMobile && (
               <div className="dialog-header">
                  <div className={`img-close d-none d-md-flex`} style={{ cursor: "pointer" }} onClick={onClose}>
                     <img src="/assets/img/icon/close.svg" alt="Space T" />
                  </div>
               </div>
            )}
            {children}
         </Dialog>
      );
   }
   return (
      <Drawer
         className={`modal-receive ${fullHeight ? "full-height" : ""}`}
         anchor="bottom"
         open={Boolean(visible)}
         onClose={onClose}
         {...props}
      >
         {isMobile && (
            <div className="drawer-header">
               <span>Coin</span>
               <div className={`img-close`} style={{ cursor: "pointer" }} onClick={onClose}>
                  <img src="/assets/img/icon/close.svg" alt="Space T" />
               </div>
            </div>
         )}
         {children}
      </Drawer>
   );
}

export default ModalReceiveCoin;
