import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import linkifyHtml from "linkify-html";
import { createPortal } from "react-dom";
import styles from "./inputHyperlink.module.scss";

export interface NewRefObject extends React.RefObject<HTMLTextAreaElement> {
   key: string;
   position: number;
}

interface IProps {
   initialValue?: string;
   innerRef: any;
   controlRef?: any;
   html: any;
   onChange: (arg: any) => void;
   className?: string;
   [t: string]: any;
}

function InputHyperlinkv2({ innerRef, controlRef, initialValue, html, onChange, className, ...props }: IProps) {
   const renderRef = useRef<HTMLDivElement>(null);

   const [dialog, setShowDialog] = useState<any>({
      show: false,
      top: 0,
      left: 0,
   });
   const [position, setPostiion] = useState<number>(0);

   // const [history, setHistory] = useState<any[]>([]);
   // Set Init app
   const [isInit, setIsInit] = useState(true);
   useEffect(() => {
      if (!isInit) return;
      if (innerRef.current && isInit && initialValue) {
         innerRef.current.value = initialValue;
         renderOverLay(initialValue);
         setPostiion(initialValue.length);
         setIsInit(false);
         setInputHeight();
      }
      // close set init value after 500ms
      setTimeout(() => {
         setIsInit(false);
      }, 500);
   }, [isInit, initialValue]);

   /**
    * Handle add emoji
    */
   useImperativeHandle(
      controlRef,
      () => ({
         setEmoij(emoij) {
            if (!emoij) return;
            const currentPosi = position || 0;
            const text = innerRef.current?.value || "";
            const newText = [text.slice(0, currentPosi), emoij, text.slice(currentPosi)].join("");
            innerRef.current!.value = newText;
            renderOverLay(newText);
            setPostiion(currentPosi + 2);
            onChange(parseToHTMLLink(newText));
         },
      }),
      [controlRef, innerRef, position, onChange]
   );

   // const appendNewText = (newText = "", pos = 0) => {
   //    const currentPosi = pos;
   //    const text = innerRef.current?.value || "";
   //    const newText2 = [text.slice(0, position), newText, text.slice(position)].join("");
   //    innerRef.current!.value = newText2;
   //    renderOverLay(newText2);
   //    setPostiion(currentPosi + newText.length);
   // };

   /**
    * Other handler
    */
   function getCursorPos(input) {
      if ("selectionStart" in input && document.activeElement == input) {
         return {
            start: input.selectionStart,
            end: input.selectionEnd,
         };
      } else if (input.createTextRange) {
         var sel = (document as any).selection.createRange();
         if (sel.parentElement() === input) {
            var rng = input.createTextRange();
            rng.moveToBookmark(sel.getBookmark());
            for (var len = 0; rng.compareEndPoints("EndToStart", rng) > 0; rng.moveEnd("character", -1)) {
               len++;
            }
            rng.setEndPoint("StartToStart", input.createTextRange());
            for (
               var pos = { start: 0, end: len };
               rng.compareEndPoints("EndToStart", rng) > 0;
               rng.moveEnd("character", -1)
            ) {
               pos.start++;
               pos.end++;
            }
            return pos;
         }
      }
   }

   const parseToHTMLLink = (textStr) => {
      const _html = linkifyHtml(textStr, {
         // defaultProtocol: "https",
         // validate: {
         //    url: value => isUrlLink(value)
         // },
         attributes: {
            class: "link-input",
            target: "_blank",
            rel: "nofollow",
            // title: "Space T"
         },
      });
      return _html;
   };

   // function setCursorPos(input, start, end) {
   //    if (arguments.length < 3) end = start;
   //    if ("selectionStart" in input) {
   //       setTimeout(function () {
   //          input.selectionStart = start;
   //          input.selectionEnd = end;
   //       }, 1);
   //    } else if (input.createTextRange) {
   //       var rng = input.createTextRange();
   //       rng.moveStart("character", start);
   //       rng.collapse();
   //       rng.moveEnd("character", end - start);
   //       rng.select();
   //    }
   // }
   const renderOverLay = (textStr) => {
      renderRef.current!.innerHTML = parseToHTMLLink(textStr);
   };

   const setInputHeight = () => {
      const outboxHeight = renderRef.current?.scrollHeight;
      outboxHeight && (innerRef.current!.style.height = `${outboxHeight + 30}px`);
   };

   const handleOnChangeInput = (e) => {
      const pos = getCursorPos(e.currentTarget);
      setPostiion(pos?.start);

      e.currentTarget.style.height = "";
      e.currentTarget.style.height = e.currentTarget.scrollHeight + 30 + "px";

      const text = e.currentTarget.value;
      renderOverLay(text);
      onChange(parseToHTMLLink(text));
   };
   const handleMouseOut = (e) => {
      const pos = getCursorPos(e.currentTarget);
      pos?.start !== undefined && setPostiion(pos?.start);
   };
   const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      const textarea = e.target as any;
      if (e.key === "@") {
         // e.preventDefault();
         // const pos = getCursorPos(e.currentTarget);
         // setPostiion(pos?.start);
         // setShowDialog({ show: true, top: cursorX, left: cursorY });
      }
   };

   return (
      <div className={styles["winput-hyper"]}>
         {dialog.show &&
            createPortal(
               <div
                  className="mentions-tags"
                  style={{
                     top: dialog.top + "px",
                     left: dialog.left + "px",
                  }}
               >
                  abcabc
               </div>,
               document.body
            )}
         <div className={styles["box-textarea"]}>
            <textarea
               ref={innerRef}
               id="code"
               placeholder="Nhập chia sẻ của bạn ở đây..."
               className={styles["input-textarea"]}
               onChange={handleOnChangeInput}
               onMouseUp={handleMouseOut}
               onKeyDown={handleKeyDown}
            />
            <div ref={renderRef} className={styles["render-overlay"]} />
         </div>
      </div>
   );
}

export default React.memo(InputHyperlinkv2);
